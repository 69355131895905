<div @flyIn>
  <div class="s-logo">
    <img src="/assets/images/enphase-logo.svg" width="198" />
  </div>

  <h1 class="solar-today-txt">{{ constants.labels.go_solar }}</h1>

  <p class="right-system-txt">{{ constants.labels.find_right_system_size }}</p>

  <div class="text-center">
    <button class="wt-outline-btn" type="button" (click)="goToEnterPin()">
      {{ constants.labels.get_started }}
    </button>
  </div>
</div>
