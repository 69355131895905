import { Injectable } from '@angular/core';

import baseapi from './base/baseapi.json';

import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ElecricityBillService {
  constructor(private http: HttpClient) {}

  getHeader(): any {
    return new HttpHeaders()
      .set('enctype', 'multipart/form-data')
      .set('Accept', 'application/json');
  }

  getEBilDatasForUK(
    country: any,
    pincode: any,
    averageMonthlyElectricityBill: any,
    tariffType: any,
    billingCycle: any
  ): any {
    return this.http.get(baseapi.getDatas, {
      params: {
        country,
        pincode,
        averageMonthlyElectricityBill,
        tariffType,
        billingCycle,
      },
      headers: this.getHeader(),
      observe: 'response',
    });
  }
  getEBilDatasForIN(
    country: any,
    pincode: any,
    averageMonthlyElectricityBill: any
  ): any {
    return this.http.get(baseapi.getDatas, {
      params: { country, pincode, averageMonthlyElectricityBill },
      headers: this.getHeader(),
      observe: 'response',
    });
  }

  getEBilUnitsDatasForUK(
    country: any,
    pincode: any,
    monthlyBill: any,
    tariffType: any,
    billingCycle: any,
    editUnits: any
  ): any {
    return this.http.get(baseapi.getEditedUnitsData, {
      params: {
        country,
        pincode,
        monthlyBill,
        tariffType,
        billingCycle,
        editUnits,
      },
      headers: this.getHeader(),
      observe: 'response',
    });
  }
  getEBilUnitsDatasForIN(
    country: any,
    pincode: any,
    editUnits: any,
    averageMonthlyElectricityBill: any
  ): any {
    return this.http.get(baseapi.getEditedUnitsData, {
      params: {
        country,
        pincode,
        editUnits,
        averageMonthlyElectricityBill,
      },
      headers: this.getHeader(),
      observe: 'response',
    });
  }

  getRegionData(country: any, pincode: any): any {
    return this.http.get(baseapi.getPincodeData, {
      params: { country, pincode },
      headers: this.getHeader(),
      observe: 'response',
    });
  }
}
