import { Component, OnInit } from '@angular/core';
import { Constant } from '../shared/constant';
import { environment } from '..//../environments/environment';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  constants: Constant;
  enphaseUrl: any;
  country: any;
  constructor(private constant: Constant) {
    this.constants = this.constant;
    this.enphaseUrl = environment;
    this.country = localStorage.getItem('country');
  }

  ngOnInit(): void {}
}
