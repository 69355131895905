<div class="thankyou-modal text-center">
  <button class="close-btn" mat-dialog-close>
    <img src="/assets/images/close_icon.svg" width="14" height="14" />
  </button>

  <p class="info-label mb-20 grey">
    Electricity bill is too low to recommend a beneficial solar solution. Please
    connect with our solar experts in case of any further queries.
  </p>
</div>
