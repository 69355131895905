<div>
  <h2 class="steps-heading">{{ constants.labels.heading }}</h2>

  <form [formGroup]="eBillForm"  [ngClass]="country === constants.labels.IN ? 'step-form-wrapper mt-50' : 'step-form-wrapper'" @flyIn>
    <p class="info-label mb-20 text-center">
      {{
        country === constants.labels.UK
          ? constants.labels.enter_ebill
          : constants.labels.enter_ebill_in
      }}
    </p>
    <div class="text-center">
      <img
        src="{{ '/assets/images/' + country + '/bill.svg' }}"
        class="map-icon mb-20"
        alt="
      Icon"
        width="52"
        height="50"
      />
    </div>
    <div *ngIf="country === constants.labels.UK">
      <div class="row px-md-4 mb-2">
        <div class="col-12 col-sm-4">
          <p class="c-radio-l">{{ constants.labels.tariff_type }}</p>
        </div>
        <div class="col-12 col-sm-8">
          <div class="c-radio-wrap">
            <label>
              <input
                type="radio"
                name="tariff"
                value="Standard"
                formControlName="tariff"
              />
              <span>{{ constants.labels.standard_tariff }}</span>
            </label>
            <label>
              <input
                type="radio"
                name="tariff"
                value="Economy"
                formControlName="tariff"
              />
              <span>{{ constants.labels.economy }}</span>
            </label>
          </div>
        </div>
      </div>

      <div class="row px-md-4 mb-4">
        <div class="col-12 col-sm-4">
          <p class="c-radio-l">{{ constants.labels.billing_cycle }}</p>
        </div>
        <div class="col-12 col-sm-8">
          <div class="c-radio-wrap">
            <label>
              <input
                type="radio"
                name="cycle"
                value="Monthly"
                formControlName="cycle"
              />
              <span>{{ constants.labels.monthly }}</span>
            </label>
            <label>
              <input
                type="radio"
                name="cycle"
                value="Quarterly"
                formControlName="cycle"
              />
              <span>{{ constants.labels.quarterly }}</span>
            </label>
          </div>
        </div>
      </div>
    </div>

    <input
      type="number"
      class="round-input text-center"
      placeholder="{{
        country === constants.labels.UK
          ? constants.labels.euro
          : constants.labels.rupee
      }}"
      formControlName="bill"
    />
    <div *ngIf="country === constants.labels.UK">
      <div *ngIf="showUnitsInfo" class="text-center">
        <p class="units-con-txt">
          {{ constants.labels.estimated }}
          <span
            >{{ slideValue }}
            {{
              country === constants.labels.UK
                ? constants.labels.units_per_month
                : constants.labels.units
            }}</span
          >
        </p>
        <span
          class="units-info-icon"
          (click)="showTable = !showTable"
          (mouseenter)="showTable = true"
          (mouseleave)="showTable = false"
        >
          <img src="/assets/images/question_mark.svg" width="17" height="17" />

          <div class="average-slab-table" *ngIf="showTable">
            <div class="t-scroller">
              <p class="t-header">{{ constants.labels.uk_avg_rates }}</p>
              <p class="des-row"></p>

              <p class="des-seprator">
                <b
                  >{{
                    eBillForm.value.tariff === constants.labels.Economy
                      ? constants.labels.Economy
                      : constants.labels.Standard
                  }}
                  tariff Charges</b
                >
              </p>
              <div *ngIf="eBillForm.value.tariff === constants.labels.Standard">
                <p class="des-row">
                  <span class="des-col">{{
                    constants.labels.standing_charge
                  }}</span>
                  <span class="des-col">{{
                    tariffData.standingCost | currency: "GBP":"symbol"
                  }}</span>
                </p>
                <p class="des-row">
                  <span class="des-col">{{
                    constants.labels.variable_charge
                  }}</span>
                  <span class="des-col">{{
                    tariffData.variableCharge | currency: "GBP":"symbol"
                  }}</span>
                </p>
              </div>
              <div *ngIf="eBillForm.value.tariff === constants.labels.Economy">
                <p class="des-row">
                  <span class="des-col">{{
                    constants.labels.standing_charge
                  }}</span>
                  <span class="des-col">{{
                    tariffData.standingCost | currency: "GBP":"symbol"
                  }}</span>
                </p>
                <p class="des-row">
                  <span class="des-col">{{
                    constants.labels.peek_charge
                  }}</span>
                  <span class="des-col">{{
                    tariffData.peakTimeCharge | currency: "GBP":"symbol"
                  }}</span>
                </p>
                <p class="des-row">
                  <span class="des-col">{{
                    constants.labels.off_peek_charge
                  }}</span>
                  <span class="des-col">{{
                    tariffData.offPeakTimeCharge | currency: "GBP":"symbol"
                  }}</span>
                </p>
              </div>

              <p class="info-text">
                {{ constants.labels.tariff_info }}
              </p>
            </div>
          </div>
          <!-- ./average-slab-table -->
        </span>
        <a class="edit-link" (click)="showSlider()">Edit</a>
      </div>

      <div class="edit-slider-wrapper" *ngIf="showEdit">
        <div class="unit-box s">{{ slideValue }}</div>
        <p class="units-txt s">
          {{
            country === constants.labels.UK
              ? constants.labels.units_per_month
              : constants.labels.units
          }}
        </p>
        <a class="unit-done sa" (click)="showSlider()">DONE</a>
        <mat-slider
          class="c-slider"
          [min]="minUnit"
          [max]="maxUnit"
          step="1"
          [value]="slideValue"
          (input)="slideValue = $event.value || slideValue"
          (change)="getSliderValue(slideValue)"
        >
        </mat-slider>
      </div>
    </div>
    <div *ngIf="country === constants.labels.IN">
      <div *ngIf="showUnitsInfo; else empty" class="text-center">
        <p class="units-con-txt">
          {{ constants.labels.estimated }}
          <span
            >{{ slideValue }}
            {{
              country === constants.labels.UK
                ? constants.labels.units_per_month
                : constants.labels.units
            }}</span
          >
        </p>
        <span
          class="units-info-icon"
          (click)="showTable = !showTable"
          (mouseenter)="showTable = true"
          (mouseleave)="showTable = false"
        >
          <img src="/assets/images/question_mark.svg" width="17" height="17" />

          <div class="average-slab-table" *ngIf="showTable">
            <div class="t-scroller">
              <p class="t-header">
                {{ reports.stateName }} {{ constants.labels.avg_slab_rate }}
              </p>
              <p class="des-row">
                <span class="des-col">{{ constants.labels.fixed_charge }}</span>
                <span class="des-col">{{
                  reports.fixedCharge | currency: "INR"
                }}</span>
              </p>
              <p class="des-seprator">{{ constants.labels.energy_charge }}</p>
              <div *ngIf="reports.stateName === 'KERALA'; else states">
                <p class="des-row">{{ constants.labels.telescopic }}</p>
                <p class="des-row">
                  <span class="des-col">{{ constants.labels.unit }}</span>
                  <span class="des-col">{{ constants.labels.rate }}</span>
                </p>
                <p class="des-row" *ngFor="let item of telescopic">
                  <span class="des-col">{{ item.units }}</span>
                  <span class="des-col">{{
                    item.energyCharge | currency: "INR"
                  }}</span>
                </p>

                <p class="des-row">{{ constants.labels.non_telescopic }}</p>
                <p class="des-row">
                  <span class="des-col">{{ constants.labels.unit }}</span>
                  <span class="des-col">{{ constants.labels.rate }}</span>
                </p>
                <p class="des-row" *ngFor="let item of nontelescopic">
                  <span class="des-col">{{ item.units }}</span>
                  <span class="des-col">{{
                    item.energyCharge | currency: "INR"
                  }}</span>
                </p>
              </div>
              <ng-template #states>
                <p class="des-row">
                  <span class="des-col">{{ constants.labels.unit }}</span>
                  <span class="des-col">{{ constants.labels.rate }}</span>
                </p>
                <p class="des-row" *ngFor="let item of reports.slabRates">
                  <span class="des-col">{{ item.units }}</span>
                  <span class="des-col">{{
                    item.energyCharge | currency: "INR"
                  }}</span>
                </p>
              </ng-template>

              <p class="info-text">
                {{ constants.labels.tariff_info }}
              </p>
            </div>
          </div>
          <!-- ./average-slab-table -->
        </span>
        <!-- ./units-info-icon -->
        <a class="edit-link" (click)="showSlider()">Edit</a>
      </div>
      <ng-template #empty>
        <p class="units-con-txt">&nbsp;</p>
      </ng-template>

      <div class="edit-slider-wrapper" *ngIf="showEdit">
        <div class="unit-box">{{ slideValue }}</div>
        <p class="units-txt">
          {{
            country === constants.labels.UK
              ? constants.labels.units_per_month
              : constants.labels.units
          }}
        </p>
        <a class="unit-done" (click)="showSlider()">DONE</a>
        <mat-slider
          class="c-slider"
          [min]="minUnit"
          [max]="maxUnit"
          step="1"
          [value]="slideValue"
          (input)="slideValue = $event.value || slideValue"
          (change)="getSliderValue(slideValue)"
        >
        </mat-slider>
      </div>
    </div>

    <div class="d-flex mt">
      <button type="button" class="outline-btn gray" (click)="prevStep()">
        Back
      </button>
      <button
        type="button"
        class="ml-auto wt-outline-btn orange"
        (click)="nextStep(eBillForm)"
        [disabled]="eBillForm.status === 'INVALID'"
        [ngClass]="{ disabled: eBillForm.status === 'INVALID' }"
      >
        {{ constants.labels.show_my_system }}
      </button>
    </div>
    <p
      class="form-info mt-3 text-center"
      *ngIf="country === constants.labels.UK"
    >
      {{ constants.labels.uk_note }}
    </p>
  </form>
</div>
