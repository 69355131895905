import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(private _snackBar: MatSnackBar) {}

  /* Accepts message to be displayed in notification */
  success(msg: string) {
    this.openNotification(msg, 'c-success-notif');
  }

  warning(msg: string) {
    this.openNotification(msg, 'c-warning-notif');
  }

  error(msg: string) {
    this.openNotification(msg, 'c-error-notif');
  }

  /* Accepts class for type of notification */
  openNotification(msg: string, nClass: string) {
    this._snackBar.open(msg, 'Close', {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: nClass,
      duration: 3000,
    });
  }
}
