import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  AfterContentInit,
} from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';

import { LoaderService } from '../app/shared/loader.service';
import { ActivatedRoute, Router } from '@angular/router';

import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { NotificationsService } from './shared/notifications.service';
import { Constant } from './shared/constant';
import { Title } from '@angular/platform-browser';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('flyIn', [
      state('in', style({ transform: 'translateY(0)', opacity: 1 })),
      transition('void => *', [
        style({ transform: 'translateY(-100%)', opacity: 0 }),
        animate('0.5s ease-out'),
      ]),
    ]),
    trigger('flyInFooter', [
      state('in', style({ transform: 'translateY(0)', opacity: 1 })),
      transition('void => *', [
        style({ transform: 'translateY(100%)', opacity: 0 }),
        animate('0.5s ease-out'),
      ]),
    ]),
  ],
})
export class AppComponent implements OnInit, OnDestroy, AfterContentInit {
  loaded = false;
  showLoader = false;
  country: any;
  newUrl: any[] = [];
  url: any;
  urlhttpClient: HttpClient;
  constants: Constant;

  @HostListener('window:onbeforeunload', ['$event'])
  clearLocalStorage(): void {
    localStorage.clear();
  }
  constructor(
    private handler: HttpBackend,
    private lService: LoaderService,
    private route: ActivatedRoute,
    private notificationService: NotificationsService,
    private constant: Constant,
    private router: Router,
    private titleService: Title
  ) {
    this.constants = this.constant;
    this.urlhttpClient = new HttpClient(this.handler);
    this.getCurrentLocation();
  }

  ngOnInit(): void {
    localStorage.clear();

    this.route.queryParams.subscribe((params) => {
      sessionStorage.setItem('utm_source', params?.utm_source || '');
      sessionStorage.setItem('utm_medium', params?.utm_medium || '');
      sessionStorage.setItem('utm_campaign', params?.utm_campaign || '');
      sessionStorage.setItem('utm_content', params?.utm_content || '');
    });
  }

  getCurrentLocation(): void {
    const nurl = environment.locationUrl;
    this.lService.showLoader();
    this.urlhttpClient
      .get(nurl, {
        headers: new HttpHeaders().set(
          'Content-Type',
          'application/x-www-form-urlencoded'
        ),
        responseType: 'json',
      })
      .subscribe(
        (res: any) => {
          this.lService.hideLoader();
          this.country = res.countryCode;
          this.country === this.constants.labels.UK
            ? this.titleService.setTitle(this.constants.titles.title_uk)
            : this.country === this.constants.labels.IN
            ? this.titleService.setTitle(this.constants.titles.title_in)
            : this.titleService.setTitle('');

          localStorage.setItem('country', this.country);

          if (
            this.country === this.constants.labels.UK ||
            this.country === this.constants.labels.IN
          ) {
            this.router.navigate(['', this.country]);
          } else {
            this.router.navigate(['/unavailable']);
          }
        },
        (err) => {
          // this.notificationService.error(err);
        }
      );

    this.country = localStorage.getItem('country');
  }
  openFeedback(): void {
    (<any>window).fby.push(['showForm', 15904]);
  }

  ngAfterContentInit(): void {
    this.lService.isLoading.subscribe((value: boolean) => {
      this.showLoader = value;
    });
  }

  ngOnDestroy(): void {
    // localStorage.clear();
    this.clearLocalStorage();
  }
}
