import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { ElecricityBillService } from '../shared/elecricity-bill.service';
import { LoaderService } from '../shared/loader.service';
import { MatDialog } from '@angular/material/dialog';
import { LessFivehundredComponent } from '../less-fivehundred/less-fivehundred.component';
import { LargePVSizeModalComponent } from '../large-pvsize-modal/large-pvsize-modal.component';
import { NotificationsService } from '../shared/notifications.service';
import { Constant } from '../shared/constant';
import { ElectricityBillServiceIN } from '../shared/services/_ind/electricity-bill.service';
import { ElectricityBillServiceUK } from '../shared/services/_uk/electricity-bill.service';
import { DirectContactWithExpertsComponent } from '../direct-contact-with-experts/direct-contact-with-experts.component';

@Component({
  selector: 'app-average-bill',
  templateUrl: './average-bill.component.html',
  styleUrls: ['./average-bill.component.scss'],
  animations: [
    trigger('flyIn', [
      state('in', style({ transform: 'translateY(0)', opacity: 1 })),
      transition('void => *', [
        style({ transform: 'translateY(-14px)', opacity: 0 }),
        animate('0.5s ease-out'),
      ]),
    ]),
  ],
})
export class AverageBillComponent implements OnInit {
  eBillForm: FormGroup;
  units = 0;
  showEdit = false;
  slideValue = 0;
  showTable = false;
  message = '';
  reports: any;
  bills: any;
  pincode: any;
  minUnit = 0;
  maxUnit = 0;
  showUnitsInfo = false;
  pvSize = 0;
  kSlabRates = [];
  telescopic: any[] = [];
  nontelescopic: any[] = [];

  unitsSlideValueChanged = false;
  addUnitsFromLocalstorage = false;
  country: any;
  tariffData: any;
  constants: Constant;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private slabService: ElecricityBillService,
    private ukService: ElectricityBillServiceUK,
    private indService: ElectricityBillServiceIN,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private loadService: LoaderService,
    private notificationService: NotificationsService,
    private constant: Constant
  ) {
    this.constants = this.constant;
    this.eBillForm = this.formBuilder.group({
      bill: ['', Validators.required],
      tariff: [this.constants.labels.Standard, Validators.required],
      cycle: [this.constants.labels.monthly, Validators.required],
    });
  }

  ngOnInit(): void {
    this.pincode = localStorage.getItem('pincode');
    this.country = localStorage.getItem('country');
    this.country === this.constants.labels.UK
      ? this.getResultsForUK(this.pincode)
      : this.getResutltsForIN(this.pincode);
    this.onValueChanges();

    document.querySelector('.main-bg')?.classList.add('dark-bg');
    if (this.country === this.constants.labels.UK) {
      localStorage.setItem('tariff', this.eBillForm.value.tariff);
      localStorage.setItem('cycle', this.eBillForm.value.cycle);
    }
  }

  prevStep(): void {
    this.router.navigate(['../pin'], { relativeTo: this.activatedRoute });
  }

  showSlider(): void {
    this.showEdit = !this.showEdit;
  }

  getSliderValue(e: any): void {
    this.slideValue = e;
    this.unitsSlideValueChanged = true;
  }

  onValueChanges(): void {
    this.eBillForm.valueChanges.subscribe((val) => {
      // convert number with Scientific Notation into Normal Representation
      const bill = (val.bill || 0).toLocaleString('fullwide', {
        useGrouping: false,
      });

      this.country === this.constants.labels.UK
        ? this.getUnitsForUK(
            this.country,
            this.pincode,
            bill,
            this.eBillForm.value.tariff,
            this.eBillForm.value.cycle
          )
        : this.getUnitsForIN(this.country, this.pincode, bill);
    });
  }

  nextStep(billForm: any): void {
    if (billForm.value.bill !== '') {
      if (
        billForm.value.bill <
        (this.country === this.constants.labels.UK ? 10 : 500)
      ) {
        this.country === this.constants.labels.UK
          ? this.dialog.open(DirectContactWithExpertsComponent)
          : this.openLessValueDialog();
      } else if (this.pvSize > 30) {
        this.openContactDialog();
      } else {
        /* If Units are Edited send the edited units value */
        if (this.unitsSlideValueChanged) {
          this.router.navigate(['../your-estimation', this.slideValue], {
            relativeTo: this.activatedRoute,
          });
        } else {
          this.router.navigate(['../your-estimation'], {
            relativeTo: this.activatedRoute,
          });
        }
      }
    }
  }

  openLessValueDialog(): void {
    this.dialog.open(LessFivehundredComponent);
  }

  openContactDialog(): void {
    this.dialog.open(LargePVSizeModalComponent);
  }

  getUnitsForUK(
    country: string,
    pin: string,
    bill: number,
    tariff: string,
    cycle: string
  ): void {
    if (bill > 9) {
      this.loadService.showLoader();
      this.ukService
        .getEBilDatasForUK(country, pin, bill, tariff, cycle)
        .subscribe(
          (res: any) => {
            if (res.status === 200) {
              this.loadService.hideLoader();
              this.showUnitsInfo = true;
              this.tariffData = res.body.result.tariffData;
              this.pvSize = res.body.result.pvSize;
              const steMinMax = res.body.result.estimatedUnits;
              localStorage.setItem('electricityBill', bill.toString());
              localStorage.setItem(
                'RecommendedData',
                JSON.stringify(res.body.result)
              );
              this.minUnit = Math.floor(steMinMax - (steMinMax * 15) / 100);
              this.maxUnit = Math.floor(steMinMax + (steMinMax * 15) / 100);
              this.slideValue = steMinMax;
            }
            if (res.status === 406) {
              this.showUnitsInfo = false;
              this.notificationService.warning(
                this.constants.error.SOMETHING_WENT_WRONG
              );
            }
            if (res.status === 500) {
              this.showUnitsInfo = false;
              this.notificationService.error(
                this.constants.error.INTERNAL_ERROR
              );
            }
            this.loadService.hideLoader();
          },
          (err: any) => {
            this.showUnitsInfo = false;
            // this.notificationService.error(
            //   this.constants.error.FETCH_DATA_ERROR
            // );
          }
        );
    }
  }
  getUnitsForIN(country: any, pincode: any, bill: any): void {
    if (Number(bill) !== 0 && Number(bill) > 499) {
      this.loadService.showLoader();
      this.indService.getEBilDatasForIN(country, pincode, bill).subscribe(
        (res: any) => {
          if (res.status === 200) {
            this.loadService.hideLoader();
            this.showUnitsInfo = true;
            this.pvSize = res.body.result.pvSize;
            const steMinMax = res.body.result.units;
            localStorage.setItem('electricityBill', bill);
            localStorage.setItem(
              'RecommendedData',
              JSON.stringify(res.body.result)
            );
            this.minUnit = Math.floor(steMinMax - (steMinMax * 15) / 100);
            this.maxUnit = Math.floor(steMinMax + (steMinMax * 15) / 100);

            this.slideValue = steMinMax;
          }
          if (res.status === 406) {
            this.showUnitsInfo = false;
            this.loadService.hideLoader();
            this.notificationService.warning(
              this.constants.error.SOMETHING_WENT_WRONG
            );
          }
          if (res.status === 500) {
            this.showUnitsInfo = false;
            this.loadService.hideLoader();
            this.notificationService.error(this.constants.error.INTERNAL_ERROR);
          }
        },
        (err: any) => {
          this.showUnitsInfo = false;
          this.loadService.hideLoader();
          // this.notificationService.error(this.constants.error.FETCH_DATA_ERROR);
        }
      );
    }
    this.showUnitsInfo = false;
  }
  getResultsForUK(pin: any): void {
    localStorage.setItem('tariff', this.eBillForm.value.tariff);
    localStorage.setItem('cycle', this.eBillForm.value.cycle);
    this.slabService.getRegionData(this.country, pin).subscribe(
      (res: any) => {
        if (res.status === 200) {
          if (res.body.result) {
            this.reports = res.body.result[0];
            this.message = '';
          } else {
            this.message = res.body.message;
          }
        }
        if (res.status === 406) {
          this.notificationService.warning(
            this.constants.error.SOMETHING_WENT_WRONG
          );
        }
        if (res.status === 500) {
          this.notificationService.error(this.constants.error.INTERNAL_ERROR);
        }
        this.loadService.hideLoader();
      },
      (err: any) => {
        // this.notificationService.error(this.constants.error.FETCH_DATA_ERROR);
        this.loadService.hideLoader();
      }
    );
  }

  getResutltsForIN(pin: any): void {
    this.slabService.getRegionData(this.country, pin).subscribe(
      (res: any) => {
        if (res.status === 200) {
          this.reports = res.body.result;
          if (this.reports?.stateName === 'KERALA') {
            this.kSlabRates = res.body.result.slabRates;
            for (let i = 0; i < this.kSlabRates.length; i++) {
              if (i < 5) {
                this.telescopic.push(this.kSlabRates[i]);
              } else {
                this.nontelescopic.push(this.kSlabRates[i]);
              }
            }
          }
        }
        if (res.status === 406) {
          this.notificationService.warning(
            this.constants.error.SOMETHING_WENT_WRONG
          );
        }
        if (res.status === 500) {
          this.notificationService.error(this.constants.error.INTERNAL_ERROR);
        }
      },
      (err: any) => {
        this.loadService.hideLoader();
        // this.notificationService.error(this.constants.error.FETCH_DATA_ERROR);
      }
    );
  }
}
