<div
  [ngClass]="
    country === constants.labels.IN
      ? 'main-bg-in'
      : country === constants.labels.UK
      ? 'main-bg-uk'
      : 'main-bg-in'
  "
>
  <div
    [ngClass]="country === constants.labels.IN ? 'shadow' : 'shadow-uk'"
  ></div>
  <div class="page-content">
    <app-header @flyIn></app-header>
    <router-outlet></router-outlet>
    <app-footer @flyInFooter></app-footer>
  </div>
  <div
    class="
      d-xl-flex
      flex-column
      d-none
      p-2
      align-items-center
      text-center
      position-absolute
      feedbackButton
    "
  >
    <div class="c-pointer blue" (click)="openFeedback()">
      Share your feedback
    </div>

    <img
      src="assets/images/feedback-desktop.svg"
      alt="feedback icon"
      style="transform: scale(1.4)"
      class="my-2"
    />
  </div>
  <!-- Loader -->
  <div class="page-loading" *ngIf="showLoader">
    <div class="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</div>
