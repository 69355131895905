import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-less-fivehundred',
  templateUrl: './less-fivehundred.component.html',
  styleUrls: ['./less-fivehundred.component.scss'],
})
export class LessFivehundredComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<LessFivehundredComponent>) {}

  ngOnInit(): void {}
  close(): void {
    this.dialogRef.close();
  }
}
