<div class="thankyou-modal text-center">
  <button class="close-btn" mat-dialog-close>
    <img src="/assets/images/close_icon.svg" width="14" height="14" />
  </button>

  <h5 class="orange mb-1 t-title">Thank you for your interest in Enphase</h5>
  <p class="mb-2 fs">We will be in touch soon.</p>
  <small class="mb-1 fs-s"> Try to learn more about Enphase visit </small><br />
  <small
    ><a
      href="{{
        country === constants.labels.UK
          ? constants.links.enphase_uk
          : constants.links.enphase_ind
      }}"
      target="_blank"
      class="blue"
      >{{
        country === constants.labels.UK
          ? "enphase.com/en-uk"
          : "enphase.com/en-in"
      }}</a
    ></small
  >
</div>
