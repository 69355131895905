import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { Constant } from '../shared/constant';

@Component({
  selector: 'app-get-started',
  templateUrl: './get-started.component.html',
  styleUrls: ['./get-started.component.scss'],
  animations: [
    trigger('flyIn', [
      state('in', style({ transform: 'translateY(0)', opacity: 1 })),
      transition('void => *', [
        style({ transform: 'translateY(-14px)', opacity: 0 }),
        animate('0.5s ease-out'),
      ]),
    ]),
  ],
})
export class GetStartedComponent implements OnInit {
  constants: Constant;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private constant: Constant
  ) {
    this.constants = this.constant;
  }

  ngOnInit(): void {
    sessionStorage.setItem('duration', new Date().toISOString());
    document
      .querySelector(
        localStorage.getItem('country') === this.constants.labels.UK
          ? '.main-bg-uk'
          : '.main-bg-in'
      )
      ?.classList.remove('dark-bg');
  }

  goToEnterPin() {
    this.router.navigate(['./pin'], { relativeTo: this.route });
  }
}
