import { HttpClient, HttpBackend, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { DirectContactWithExpertsComponent } from '../direct-contact-with-experts/direct-contact-with-experts.component';
import { CaptchaService } from '../shared/captcha.service';
import { Constant } from '../shared/constant';
import { ElecricityBillService } from '../shared/elecricity-bill.service';
import { LoaderService } from '../shared/loader.service';
import { MailService } from '../shared/mail.service';
import { NotificationsService } from '../shared/notifications.service';
import { ThankyoudialogComponent } from '../thankyoudialog/thankyoudialog.component';
import { environment } from 'src/environments/environment';
import { CurrencyPipe } from '@angular/common';
@Component({
  selector: 'app-large-pvsize-modal',
  templateUrl: './large-pvsize-modal.component.html',
  styleUrls: ['./large-pvsize-modal.component.scss'],
})
export class LargePVSizeModalComponent implements OnInit {
  contactForm: FormGroup;
  private urlhttpClient: HttpClient;
  indUrl: any;
  bills: any;
  estPrice: any;
  slideValue: any;
  docService: any;
  units: any;
  ebill: any;
  pincode: any;
  tariff: any;
  cycle: any;
  country: string | null;
  captchaData = { image: '', text: '' };
  wrongCaptcha = false;
  constants: Constant;
  ukUrl: any;
  constructor(
    handler: HttpBackend,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<DirectContactWithExpertsComponent>,
    public dialog: MatDialog,
    private mailService: MailService,
    private loadService: LoaderService,
    private eService: ElecricityBillService,
    private notificationService: NotificationsService,
    private captchaService: CaptchaService,
    private constant: Constant,
    private currencyPipe: CurrencyPipe
  ) {
    this.constants = this.constant;
    this.country = localStorage.getItem('country');
    this.contactForm = this.formBuilder.group({
      name: new FormControl('', [Validators.required]),
      phone: new FormControl('', [
        Validators.required,
        Validators.maxLength(10),
        Validators.minLength(10),
        Validators.pattern('^[0-9]+$'),
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.pattern('^[^@\\s]+@[^@\\s]+\\.[^@\\s]+$'),
      ]),
      pincode: new FormControl(localStorage.getItem('actualPincode'), [
        Validators.required,
      ]),
      interest: new FormControl(0),
      accept: new FormControl('', [Validators.required]),
      captcha: new FormControl('', [Validators.required]),
    });
    this.urlhttpClient = new HttpClient(handler);
  }

  ngOnInit(): void {
    this.loadCaptcha();
  }

  reloadCaptcha(): void {
    this.loadCaptcha();
  }

  loadCaptcha(): void {
    this.captchaService.getCaptcha().subscribe(
      (res: any) => {
        this.captchaData = res.body;
      },
      (err: any) => {
        this.notificationService.error('Error in Fetching the Captcha');
      }
    );
  }
  onSubmit(): void {
    console.log(this.contactForm);
    if (this.contactForm.value.captcha !== this.captchaData.text) {
      this.wrongCaptcha = true;
      this.loadCaptcha();
      return;
    } else {
      this.wrongCaptcha = true;
    }
    if (this.contactForm.invalid) {
      return;
    } else {
      (<any>window).dataLayer.push({ event: 'Submit Event - 1' });
      this.close();
      this.getEbillFromAPI();
      this.loadService.showLoader();
    }
  }
  private mailServiceFunc(res: any): void {
    const result = JSON.parse(Object(res));
    this.mailService
      .sendMail(
        this.country === this.constants.labels.UK
          ? {
              email: this.contactForm.get('email')?.value,
              country: this.country,
              phone: this.contactForm.get('phone')?.value,
              pincode: this.contactForm.get('pincode')?.value,
              name: this.contactForm.get('name')?.value,
              monthlyElectricityBill: this.currencyPipe.transform(
                Number(result.monthlyBill).toFixed(2),
                'GBP',
                'symbol',
                '1.0-0'
              ),
              units: result.estimatedUnits.toString() || 'null',
              panels: result.panelsNeeded.toString() || 'null',
              roofArea: result.roofSize.toString() || 'null',
              payBackPeriod: result.payBackperiod.toString() || 'null',
              totalCost: this.currencyPipe.transform(
                Number(result.estSystemPrice).toFixed(2),
                'GBP',
                'symbol',
                '1.0-0'
              ),
              withoutSolar: this.currencyPipe.transform(
                Number(result.newElectrictyBill).toFixed(2),
                'GBP',
                'symbol',
                '1.0-0'
              ),
              pvSize: result.pvSize.toString() || 'null',
              tariffType: result.tariffType || 'null',
              billingCycle: result.billingCycle || 'null',
              savingsWithSEGPerYear: this.currencyPipe.transform(
                Number(result.savingsWithSEGPerYear).toFixed(2),
                'GBP',
                'symbol',
                '1.0-0'
              ),
              savingsWithoutSEGPerYear: this.currencyPipe.transform(
                Number(result.savingsWithoutSEGPerYear).toFixed(2),
                'GBP',
                'symbol',
                '1.0-0'
              ),
            }
          : {
              email: this.contactForm.get('email')?.value,
              country: this.country,
            }
      )
      .subscribe(
        (res: any) => {
          this.loadService.hideLoader();
          if (res.status === 200) {
            this.dialog.open(ThankyoudialogComponent);
            this.sendCURLRequest();
          }
          if (res.status === 406) {
            this.notificationService.warning(' Something Went Wrong');
          }
          if (res.status === 500) {
            this.notificationService.error(' Internal Error');
          }
        },
        (err: any) => {
          this.notificationService.error(' Error in fetching data');
          this.loadService.hideLoader();
        }
      );
  }

  close(): void {
    this.dialogRef.close();
  }
  getEbillFromAPI(): void {
    this.units = localStorage.getItem('unit');
    this.ebill = localStorage.getItem('electricityBill');
    this.pincode = localStorage.getItem('pincode');
    this.tariff = localStorage.getItem('tariff');
    this.cycle = localStorage.getItem('cycle');
    this.mailServiceFunc(localStorage.getItem('RecommendedData'));
  }
  sendCURLRequest(): void {
    // Add all the Data in URL
    let full_name = this.contactForm.value.name;
    let first_name = full_name?.split(' ')[0];
    let last_name = full_name?.split(' ')[1];
    let phone = this.contactForm.value.phone;
    let email = this.contactForm.value.email;
    let zipcode = localStorage.getItem('pincode');
    let zipcodeUK = localStorage.getItem('actualPincode');
    const bill = localStorage.getItem('electricityBill');
    // const unit = localStorage.getItem('unit');

    let utmSource = sessionStorage.getItem('utm_source');
    let utmMedium = sessionStorage.getItem('utm_medium');
    let utmCamp = sessionStorage.getItem('utm_campaign');
    let utmContent = sessionStorage.getItem('utm_content');
    let time = sessionStorage.getItem('duration');

    let timeSpent = new Date(time as any).getTime();
    const strObject = localStorage.getItem('RecomendedData');
    let recObj = JSON.parse(Object(strObject));
    const curentTime = new Date().getTime();
    const state = localStorage.getItem('state');
    const interest = Number(this.contactForm.value.interest);

    const spent = Math.floor((curentTime - timeSpent) / 1000);

    let detectOS = 'Unknown OS';
    let browserName = 'Unknown';
    if (navigator.platform.indexOf('Win') !== -1) {
      detectOS = 'Windows';
    }

    if (navigator.platform.indexOf('Mac') !== -1) {
      detectOS = 'MacOS';
    }

    if (navigator.platform.indexOf('Linux') !== -1) {
      detectOS = 'Linux';
    }
    const osv = navigator.appVersion.split(' ');
    const v = [];
    v.push(osv[1]);
    v.push(osv[2]);
    v.push(osv[3]);
    v.push(osv[4]);
    v.push(osv[5]);
    v.join(' ');

    if (
      (navigator.userAgent.indexOf('Opera') ||
        navigator.userAgent.indexOf('OPR')) !== -1
    ) {
      browserName = 'Opera';
    } else if (navigator.userAgent.indexOf('Chrome') !== -1) {
      browserName = 'Chrome';
    } else if (navigator.userAgent.indexOf('Safari') !== -1) {
      browserName = 'Safari';
    } else if (navigator.userAgent.indexOf('Firefox') !== -1) {
      browserName = 'Firefox';
    } else if (navigator.userAgent.indexOf('MSIE') !== -1) {
      // IF IE > 10
      browserName = 'IE';
    } else {
      browserName = 'Unknown';
    }

    const bVersion = (() => {
      var ua = navigator.userAgent,
        tem,
        M =
          ua.match(
            /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
          ) || [];
      if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return 'IE ' + (tem[1] || '');
      }
      if (M[1] === 'Chrome') {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
        if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
      }
      M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
      if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
      return M.join(' ');
    })();

    this.indUrl = `
      ${environment.lead_conduit_IND}?
      first_name=${first_name}&last_name=${last_name}&email=${email}&zipcode=${zipcode}&contact_number=${phone}
      &country=${this.country}&consent=TRUE&product=solar&home_size=${
      recObj.idealRoofSize
    }&pv_capacity=${
      recObj.pvSize
    }&utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCamp}&utm_content=${utmContent}
      &time_spent_on_page=${
        spent + ' s'
      }&os_name=${detectOS}&os_version=${v}&browser_name=${browserName}&browser_version=${bVersion}
      &navigator_user_agent=${navigator.userAgent}&navigator_app_version=${
      navigator.appVersion
    }&navigator_platform=${navigator.platform}
      &navigator_vendor=${
        navigator.vendor
      }&ec_bill_amt=${bill}&existing_panels_count=${
      recObj.panelCount
    }&total_savings=${Number(recObj.savingsFor25Years).toFixed(2)}
      &total_cost=${recObj.estimatedPrice}&payback_period=${
      recObj.payBackPeriod
    }&no_of_units=${
      recObj.units
    }&page_url=&source_details=&oid=00D63000000U0i6&new_electricity_bill=${Number(
      recObj.newMonthlyElectricityBill
    ).toFixed(
      2
    )}&original_source=${'IN System Estimator'}&state=${state}&lead_source_page_url=${
      window.location.href
    }`;

    this.ukUrl = `
      ${environment.lead_conduit_UK}?
      first_name=${first_name}&last_name=${last_name}&email=${email}&zipcode=${zipcodeUK}&contact_number=${phone}
      &country=${this.country}&consent=TRUE&product=${
      interest === 1 ? 'solarstorage' : 'solar'
    }&home_size=${recObj.roofSize}&pv_capacity=${
      recObj.pvSize
    }&utm_source=${utmSource}&utm_medium=${utmMedium}&utm_content=${utmContent}
      &time_spent_on_page=${
        spent + ' s'
      }&os_version=${v}&browser_name=${browserName}&browser_version=${bVersion}
      &navigator_user_agent=${navigator.userAgent}&navigator_app_version=${
      navigator.appVersion
    }&navigator_platform=${navigator.platform}
      &navigator_vendor=${navigator.vendor}&ec_bill_amt=${Number(
      recObj.monthlyBill
    ).toFixed(2)}&total_savings=${Number(recObj.savingsWithSEGPerYear).toFixed(
      2
    )}&total_cost=${Number(recObj.estSystemPrice).toFixed(2)}&payback_period=${
      recObj.payBackperiod
    }&existing_panels_count=${recObj.panelsNeeded}&no_of_units=${
      recObj.estimatedUnits
    }&new_electricity_bill=${Number(recObj.newElectrictyBill).toFixed(2)}
      &lead_source_page_url=${
        window.location.href
      }&original_source=${'UK System Estimator'}&state=${
      recObj.region
    }&tariff_type=${recObj.tariffType}&billing_cycle=${
      recObj.billingCycle
    }&savings_without_seg=${Number(recObj.savingsWithOutSEG).toFixed(
      2
    )}&savings_without_SEG=${Number(recObj.savingsWithoutSEGPerYear).toFixed(
      2
    )}&storage_interest=${interest === 1 ? 'true' : 'false'}`;

    this.urlhttpClient
      .get(
        this.country === this.constants.labels.UK ? this.ukUrl : this.indUrl,
        {
          headers: new HttpHeaders().set(
            'Content-Type',
            'application/x-www-form-urlencoded'
          ),
          responseType: 'text',
        }
      )
      .subscribe(
        (res: any) => {
          if (res.status === 200) {
            this.notificationService.success(' Successfully sent the data');
          }
          if (res.status === 406) {
            this.notificationService.warning('Something Went Wrong');
          }
          if (res.status === 500) {
            this.notificationService.error('Internal Error');
          }
          this.loadService.hideLoader();
        },
        (err) => {
          this.notificationService.error('Error in fetching data');
          this.loadService.hideLoader();
        }
      );
  }
}
