import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CurrencyPipe } from '@angular/common';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';

import { ConnectWithExpertsComponent } from '../connect-with-experts/connect-with-experts.component';
import { MatDialog } from '@angular/material/dialog';
import { LoaderService } from '../shared/loader.service';
import { pdfService } from '../shared/pdf.service';

import { saveAs } from 'file-saver';

import { NotificationsService } from '../shared/notifications.service';
import { IndiancurrencyPipe } from '../shared/indiancurrency.pipe';
import { Constant } from '../shared/constant';
import { ElectricityBillServiceIN } from '../shared/services/_ind/electricity-bill.service';
import { ElectricityBillServiceUK } from '../shared/services/_uk/electricity-bill.service';
import { LessFivehundredComponent } from '../less-fivehundred/less-fivehundred.component';
import { LargePVSizeModalComponent } from '../large-pvsize-modal/large-pvsize-modal.component';
import { DirectContactWithExpertsComponent } from '../direct-contact-with-experts/direct-contact-with-experts.component';
import { ElecricityBillService } from '../shared/elecricity-bill.service';
import { DetailsModalComponent } from '../details-modal/details-modal.component';

@Component({
  selector: 'app-recommended',
  templateUrl: './recommended.component.html',
  styleUrls: ['./recommended.component.scss'],
  animations: [
    trigger('flyIn', [
      state('in', style({ transform: 'translateY(0)', opacity: 1 })),
      transition('void => *', [
        style({ transform: 'translateY(-14px)', opacity: 0 }),
        animate('0.5s ease-out'),
      ]),
    ]),
  ],
})
export class RecommendedComponent implements OnInit {
  loading = false;
  slideValue = 0;
  bills: any;
  pin: any;
  units: any;
  ebill: any;

  pdf: any;

  estPrice: any;

  showDownloadButton = false;
  tariff: any;
  cycle: any;
  actualPin: any;
  isSEG = true;
  showEditSidePannel = false;

  editForm: FormGroup;
  // editFormIN: FormGroup;
  showEdit = false;
  unitSlideValue = 0;
  minUnit = 0;
  maxUnit = 0;
  country: any;
  pincode: any;
  constants: Constant;
  recommended: any;
  recommendedData: any;
  defaultTriangleValue: any;
  unitSlider: any;
  apiTimeout: any;
  message: any;

  constructor(
    private router: Router,
    private indService: ElectricityBillServiceIN,
    private ukService: ElectricityBillServiceUK,
    private eService: ElecricityBillService,
    public dialog: MatDialog,
    private loadService: LoaderService,
    private docService: pdfService,
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationsService,
    private currencyPipe: CurrencyPipe,
    private formBuilder: FormBuilder,
    private cpipe: IndiancurrencyPipe,
    private constant: Constant
  ) {
    this.constants = this.constant;
    this.pin = localStorage.getItem('pincode');
    this.ebill = localStorage.getItem('electricityBill');
    this.tariff = localStorage.getItem('tariff');
    this.cycle = localStorage.getItem('cycle');
    this.actualPin = localStorage.getItem('actualPincode');
    this.country = localStorage.getItem('country');
    const recommended = localStorage.getItem('RecommendedData') || '';
    this.recommendedData = JSON.parse(recommended);
    this.editForm = this.formBuilder.group({
      postcode: new FormControl(
        this.country === this.constants.labels.UK ? this.actualPin : this.pin,
        [Validators.required]
      ),
      tariffType: new FormControl(this.tariff, [Validators.required]),
      billingCycle: new FormControl(this.cycle, [Validators.required]),
      averageMonthlyBill: new FormControl(this.ebill, [Validators.required]),
    });

    const editedUnits = this.activatedRoute.snapshot.params.editedUnits;
    if (editedUnits) {
      this.getEbillFromEditedUnitsAPI(editedUnits, this.ebill);
      this.units = editedUnits;
    } else {
      this.country === this.constants.labels.UK
        ? this.getEbillFromUK()
        : this.getEbillFromIndia();
    }
  }

  ngOnInit(): void {
    this.loadService.showLoader();
    this.docService.showDownloadButton.subscribe((value: boolean) => {
      this.showDownloadButton = value;
    });
    this.setMinMaxUnits(
      this.country === this.constants.labels.UK
        ? this.recommendedData.estimatedUnits
        : this.recommendedData.units
    );
    /* Init Side Section Value Change */

    this.sideSectionFormValChange();

    document.querySelector('.main-bg')?.classList.add('dark-bg');
  }

  setMinMaxUnits(units: number): void {
    this.minUnit = Math.floor(units - (units * 15) / 100);
    this.maxUnit = Math.floor(units + (units * 15) / 100);
  }
  sideSectionFormValChange(): void {
    this.editForm.valueChanges.subscribe((val) => {
      // convert number with Scientific Notation into Normal Representation
      const bill = (val.averageMonthlyBill || 0).toLocaleString('fullwide', {
        useGrouping: false,
      });
      const postcode = this.editForm.value.postcode;

      if (this.country === this.constants.labels.UK) {
        if (bill > 9 && this.editForm.value.postcode.length > 3) {
          this.loadService.showLoader();
          this.pin = postcode.slice(0, -3);
          this.ebill = this.editForm.value.averageMonthlyBill;
          this.tariff = this.editForm.value.tariffType;
          this.cycle = this.editForm.value.billingCycle;
          localStorage.setItem('tariff', this.tariff);
          localStorage.setItem('cycle', this.cycle);
          clearTimeout(this.apiTimeout);
          // Start a timeout
          this.apiTimeout = setTimeout(() => {
            this.getEbillFromUK();
          }, 300);
        }
      }
      if (this.country === this.constants.labels.IN) {
        if (bill > 499) {
          this.pin = postcode;

          this.ebill = this.editForm.value.averageMonthlyBill;
          clearTimeout(this.apiTimeout);
          // Start a timeout
          this.apiTimeout = setTimeout(() => {
            this.slabData(this.pin);
          }, 300);
        }
      }
    });
  }
  slabData(pin: any): void {
    this.loadService.showLoader();
    this.eService.getRegionData(this.country, pin).subscribe(
      (res: any) => {
        if (res.status === 200) {
          this.getEbillFromIndia();
          if (typeof res.body.result === 'object') {
            this.message = '';
            localStorage.setItem('pincode', pin);
            localStorage.setItem('state', res.body.result.stateName);
          } else {
            this.message = res.body.result;
          }
        }
        if (res.status === 406) {
          this.notificationService.warning(
            this.constants.error.SOMETHING_WENT_WRONG
          );
        }
        if (res.status === 500) {
          this.notificationService.error(this.constants.error.INTERNAL_ERROR);
        }
        this.loadService.hideLoader();
      },
      (err: any) => {
        // this.notificationService.error(this.constants.error.FETCH_DATA_ERROR);
        this.loadService.hideLoader();
      }
    );
  }
  nextStep(billForm: any): void {
    const bill = (billForm.value.averageMonthlyBill || 0).toLocaleString(
      'fullwide',
      {
        useGrouping: false,
      }
    );
    if (this.country === this.constants.labels.UK) {
      if (bill > 9 && billForm.value.postcode.length > 3) {
        this.loadService.showLoader();
        this.pin = localStorage.getItem('pincode');
        this.ebill = this.editForm.value.averageMonthlyBill;
        this.tariff = this.editForm.value.tariffType;
        this.cycle = this.editForm.value.billingCycle;
        this.showEditSidePannel = false;
        this.editedBillOrUnitsForUK(this.units, this.ebill);
      } else {
        this.dialog.open(DirectContactWithExpertsComponent);
      }
    } else {
      if (bill < 500) {
        this.openLessValueDialog();
      } else {
        this.loadService.showLoader();
        this.pin = this.editForm.value.postcode;
        this.ebill = this.editForm.value.averageMonthlyBill;
        this.showEditSidePannel = false;
        this.editedBillOrUnitsForIN(this.units, this.ebill);
      }
    }
  }

  getNewUnitValue(newUnit: number): void {
    this.loadService.showLoader();
    this.unitSlider = newUnit;
    this.units = newUnit;
    if (this.country === this.constants.labels.UK) {
      this.editedBillOrUnitsForUK(newUnit, this.ebill);
    } else {
      this.editedBillOrUnitsForIN(newUnit, this.ebill);
    }
  }

  getEbillFromEditedUnitsAPI(units: number, bill: number): void {
    if (this.country === this.constants.labels.UK) {
      this.editedBillOrUnitsForUK(units, bill);
    } else {
      this.editedBillOrUnitsForIN(units, bill);
    }
  }
  getToDefault(): void {
    this.slideValue = this.defaultTriangleValue;
  }

  private editedBillOrUnitsForUK(units: number, bill: number): void {
    this.ukService
      .getEBilUnitsDatasForUK(
        this.country,
        this.pin,
        bill,
        this.tariff,
        this.cycle,
        units
      )
      .subscribe(
        (res: any) => {
          if (res.status === 200) {
            if (res.body.result.pvSize > 30) {
              this.openContactDialog();
            } else {
              this.loadService.hideLoader();
              this.recommendedData = res.body.result;

              this.estPrice = this.recommendedData.estSystemPrice.toFixed(2);
              this.slideValue = this.recommendedData.pvSize;

              this.unitSlideValue = this.recommendedData.estimatedUnits;
              // Data to be used later in CURL
              localStorage.setItem(
                'RecommendedData',
                JSON.stringify(this.recommendedData)
              );
              this.calculateDefaultValueTooltipPosition();
            }
          }
          if (res.status === 406) {
            this.notificationService.warning(
              this.constants.error.SOMETHING_WENT_WRONG
            );
          }
          if (res.status === 500) {
            this.notificationService.error(this.constants.error.INTERNAL_ERROR);
          }
          this.loadService.hideLoader();
        },
        (err: any) => {
          // this.notificationService.error('Error in fetching data');
          this.loadService.hideLoader();
        }
      );
  }

  private editedBillOrUnitsForIN(units: number, bill: number): void {
    this.indService
      .getEBilUnitsDatasForIN(this.country, this.pin, units, bill)
      .subscribe(
        (res: any) => {
          if (res.status === 200) {
            this.loadService.hideLoader();

            if (Object.keys(res.body).length > 0) {
              this.recommendedData = res.body.result;
              // Data to be used later in CURL
              localStorage.setItem(
                'RecommendedData',
                JSON.stringify(this.recommendedData)
              );
              this.estPrice = this.recommendedData.estimatedPrice.toFixed(2);
              this.unitSlideValue = this.recommendedData.units;
              this.slideValue = this.recommendedData.pvSize;
              this.loadService.hideLoader();
            } else {
              localStorage.setItem('RecommendedData', JSON.stringify({}));
              this.estPrice = 0;
              this.unitSlideValue = 0;
              this.slideValue = 0;
              this.loadService.hideLoader();
            }
          }
          if (res.status === 406) {
            this.notificationService.warning(
              this.constants.error.SOMETHING_WENT_WRONG
            );
          }
          if (res.status === 500) {
            this.notificationService.error(this.constants.error.INTERNAL_ERROR);
          }
        },
        (err: any) => {
          // this.notificationService.error('Error in fetching data');
        }
      );
  }

  getEbillFromUK(): void {
    this.estPrice = this.recommendedData.estSystemPrice.toFixed(2);
    this.slideValue = this.recommendedData.pvSize;
    this.unitSlideValue = this.recommendedData.estimatedUnits;

    this.getUnits(this.country, this.pin, this.ebill, this.tariff, this.cycle);
  }
  getEbillFromIndia(): void {
    this.slideValue = this.recommendedData?.pvSize || 0;
    this.estPrice = this.recommendedData?.estimatedPrice?.toFixed(2) || 0;
    this.unitSlideValue = this.recommendedData?.units || 0;

    this.getBills(this.country, this.pin, this.ebill);
  }
  getBills(country: string, pin: any, bill: number): void {
    this.loadService.showLoader();
    this.indService.getEBilDatasForIN(country, pin, bill).subscribe(
      (res: any) => {
        if (res.status === 200) {
          if (res.body.result.pvSize > 30) {
            if (!this.dialog.openDialogs || !this.dialog.openDialogs.length) {
              this.openContactDialog();
            }
          } else {
            this.recommendedData = res.body.result;
            // Data to be used later in CURL
            localStorage.setItem('electricityBill', this.ebill);
            localStorage.setItem(
              'RecommendedData',
              JSON.stringify(this.recommendedData)
            );
            this.setMinMaxUnits(this.recommendedData.units);
            this.estPrice =
              this.recommendedData?.estimatedPrice?.toFixed(2) || 0;
            this.unitSlideValue = this.recommendedData.units || 0;
            this.units = this.recommendedData.units || 0;

            this.slideValue = this.recommendedData.pvSize || 0;
            this.loadService.hideLoader();
          }
        }
        if (res.status === 406) {
          this.notificationService.warning(
            this.constants.error.SOMETHING_WENT_WRONG
          );
        }
        if (res.status === 500) {
          this.notificationService.error(this.constants.error.INTERNAL_ERROR);
        }
        this.loadService.hideLoader();
      },
      (err: any) => {
        // this.notificationService.error('Error in fetching data');
        this.loadService.hideLoader();
      }
    );
  }
  getUnits(
    country: string,
    pin: string,
    bill: number,
    tariff: string,
    cycle: string
  ): void {
    this.loadService.showLoader();
    this.ukService
      .getEBilDatasForUK(country, pin, bill, tariff, cycle)
      .subscribe(
        (res: any) => {
          if (res.status === 200) {
            if (res.body.result.pvSize > 30) {
              if (!this.dialog.openDialogs || !this.dialog.openDialogs.length) {
                this.openContactDialog();
              }
            } else {
              localStorage.setItem('electricityBill', this.ebill);
              this.recommendedData = res.body.result;
              this.estPrice = this.recommendedData.estSystemPrice.toFixed(2);
              this.slideValue = this.recommendedData.pvSize;
              this.units = this.recommendedData.estimatedUnits;
              this.setMinMaxUnits(this.recommendedData.estimatedUnits);
              this.unitSlideValue = this.recommendedData.estimatedUnits;
              // Data to be used later in CURL
              localStorage.setItem(
                'RecommendedData',
                JSON.stringify(this.recommendedData)
              );
              this.calculateDefaultValueTooltipPosition();
            }
          }
          if (res.status === 406) {
            this.notificationService.warning(
              this.constants.error.SOMETHING_WENT_WRONG
            );
          }
          if (res.status === 500) {
            this.notificationService.error(this.constants.error.INTERNAL_ERROR);
          }
          this.loadService.hideLoader();
        },
        (err: any) => {
          // this.notificationService.error('Error in fetching data');
          this.loadService.hideLoader();
        }
      );
  }
  calculateDefaultValueTooltipPosition(): void {
    setTimeout(() => {
      this.defaultTriangleValue = this.slideValue;
      let val = ((this.slideValue < 15 ? this.slideValue : 15) / 15) * 100;
      val = 100 - val;
      (document as any).getElementsByClassName(
        'default-value-icon'
      )[0].style.right = this.setTriangleValue(val);
    }, 300);
  }
  // triangle position
  setTriangleValue(val: any): any {
    if (val === 0) {
      return `calc(${val}% - 6px )`;
    } else if (val > 1 && val <= 25) {
      return `calc(${val}% - 5px )`;
    } else if (val > 25 && val <= 42) {
      return `calc(${val}% - 3px )`;
    } else if (val > 85 && val <= 94) {
      return `calc(${val}% + 5px )`;
    } else {
      return `calc(${val}% )`;
    }
  }
  prevStep(): void {
    this.router.navigate(['/bill/edit']);
  }

  openDialog(): void {
    this.dialog.open(ConnectWithExpertsComponent);
  }
  detailsModal(): void {
    this.dialog.open(DetailsModalComponent, {
      panelClass: 'details-popup',
    });
  }

  calleditPVSizeAPI(value: any): any {
    this.loadService.showLoader();
    if (this.country === this.constants.labels.UK) {
      this.ukService
        .editPVSizeForUK(
          this.country,
          this.pin,
          value,
          this.tariff,
          this.cycle,
          this.ebill
        )
        .subscribe(
          (res: any) => {
            if (res.status === 200) {
              this.recommendedData = res.body.result;
              this.estPrice = this.recommendedData.estSystemPrice.toFixed(0);
              localStorage.setItem(
                'RecommendedData',
                JSON.stringify(this.recommendedData)
              );
            }
            if (res.status === 406) {
              this.notificationService.warning(
                this.constants.error.SOMETHING_WENT_WRONG
              );
            }
            if (res.status === 500) {
              this.notificationService.error(
                this.constants.error.INTERNAL_ERROR
              );
            }
            this.loadService.hideLoader();
          },
          (err: any) => {
            // this.notificationService.error('Error in fetching data');
            this.loadService.hideLoader();
          }
        );
    } else {
      this.indService
        .editPVSizeForIN(this.country, this.pin, value, this.ebill)
        .subscribe(
          (res: any) => {
            if (res.status === 200) {
              this.recommendedData = res.body.result;
              localStorage.setItem(
                'RecommendedData',
                JSON.stringify(this.recommendedData)
              );
              this.estPrice =
                this.recommendedData?.estimatedPrice.toFixed(0) || 0;
              this.loadService.hideLoader();
            }
            if (res.status === 406) {
              this.notificationService.warning(
                this.constants.error.SOMETHING_WENT_WRONG
              );
            }
            if (res.status === 500) {
              this.notificationService.error(
                this.constants.error.INTERNAL_ERROR
              );
            }
          },
          (err: any) => {
            // this.notificationService.error('Error in fetching data');
          }
        );
    }
  }
  openLessValueDialog(): void {
    this.dialog.open(LessFivehundredComponent);
  }

  openContactDialog(): void {
    this.dialog.open(LargePVSizeModalComponent);
  }

  downloadPDF(): void {
    this.loadService.showLoader();

    let full_name = localStorage.getItem('name');
    const phone = localStorage.getItem('phone');
    const email = localStorage.getItem('email');
    const zipcode = localStorage.getItem('pincode');
    const actPin = localStorage.getItem('actualPincode');

    const bill = localStorage.getItem('electricityBill');
    const strObject = localStorage.getItem('RecommendedData') || '';
    const recObj = JSON.parse(strObject);

    const traESPrice = this.currencyPipe.transform(
      recObj.estSystemPrice,
      'GBP',
      'symbol',
      '1.0-0'
    );
    const traSavingsWitouthSEG =
      recObj.savingsWithoutSEGPerYear > 0
        ? this.currencyPipe.transform(
            Number(recObj.savingsWithoutSEGPerYear).toFixed(2),
            'GBP',
            'symbol',
            '1.0-0'
          )
        : '---';
    const traSavingsWithSEG =
      recObj.savingsWithSEGPerYear > 0
        ? this.currencyPipe.transform(
            Number(recObj.savingsWithSEGPerYear).toFixed(2),
            'GBP',
            'symbol',
            '1.0-0'
          )
        : '---';
    const trabill = this.currencyPipe.transform(bill, 'GBP', 'symbol', '1.0-0');
    const newElectrictyBill = this.currencyPipe.transform(
      recObj.newElectrictyBill,
      'GBP',
      'symbol',
      '1.0-0'
    );

    const unitWithComma = Number(
      this.country === this.constants.labels.UK
        ? recObj.estimatedUnits
        : recObj.units
    ).toLocaleString('en-IN');
    const tariffType = localStorage.getItem('tariff') || '';
    const billingCycle = localStorage.getItem('cycle') || '';
    if (this.country === this.constants.labels.UK) {
      this.docService
        .getPdfForUK(
          this.country,
          full_name,
          phone,
          email,
          newElectrictyBill,
          unitWithComma,
          recObj.panelsNeeded,
          recObj.roofSize,
          recObj.payBackperiod > 25
            ? '25'
            : recObj.payBackperiod < 0
            ? '0'
            : recObj.payBackperiod,
          trabill,
          actPin,
          traESPrice,
          tariffType,
          billingCycle,
          traSavingsWithSEG,
          traSavingsWitouthSEG,
          recObj.pvSize
        )
        .subscribe(
          (res: any) => {
            this.loadService.hideLoader();

            if (res.size > 0) {
              const file = new Blob([res], { type: 'application/pdf' });
              saveAs(file, `Your Enphase system details.pdf`);
            } else {
              this.notificationService.error(' Error in download the pdf');
            }
          },
          (err: any) => {
            this.loadService.hideLoader();
            // this.notificationService.error(' Error in downloading the pdf');
          }
        );
    } else {
      this.docService
        .getPdfForIN(
          full_name,
          phone,
          email,
          this.cpipe.transform(recObj.newMonthlyElectricityBill),
          unitWithComma,
          recObj.panelCount,
          recObj.idealRoofSize,
          recObj.payBackPeriod,
          this.cpipe.transform(Number(bill)),
          this.cpipe.transform(recObj.savingsFor25Years),
          zipcode,
          this.cpipe.transform(recObj.estimatedPrice),
          recObj.pvSize,
          this.country
        )
        .subscribe(
          (res: any) => {
            this.loadService.hideLoader();

            if (res.size > 0) {
              const file = new Blob([res], { type: 'application/pdf' });
              saveAs(file, `Your Enphase system details.pdf`);
            } else {
              this.notificationService.error('Error in download ');
            }
          },
          (err: any) => {
            this.loadService.hideLoader();
            // this.notificationService.error('Error in downloading the pdf');
          }
        );
    }
  }
}
