import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { ThankyoudialogComponent } from '../thankyoudialog/thankyoudialog.component';

import { MailService } from '../shared/mail.service';
import { LoaderService } from '../shared/loader.service';
import { NotificationsService } from '../shared/notifications.service';
import { CaptchaService } from '../shared/captcha.service';
import { Constant } from '../shared/constant';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-direct-contact-with-experts',
  templateUrl: './direct-contact-with-experts.component.html',
  styleUrls: [
    '../connect-with-experts/connect-with-experts.component.scss',
    './direct-contact-with-experts.component.scss',
  ],
})
export class DirectContactWithExpertsComponent implements OnInit {
  contactForm: FormGroup;
  private urlhttpClient: HttpClient;
  indUrl: any;
  country: string | null;
  captchaData = { image: '', text: '' };
  wrongCaptcha = false;
  constants: Constant;
  ukUrl: any;

  constructor(
    handler: HttpBackend,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<DirectContactWithExpertsComponent>,
    public dialog: MatDialog,
    private mailService: MailService,
    private loadService: LoaderService,
    private notificationService: NotificationsService,
    private captchaService: CaptchaService,
    private constant: Constant
  ) {
    this.constants = this.constant;
    this.country = localStorage.getItem('country');
    this.contactForm = this.formBuilder.group({
      name: new FormControl('', [Validators.required]),
      phone: new FormControl('', [
        Validators.required,
        Validators.maxLength(10),
        Validators.minLength(10),
        Validators.pattern('^[0-9]+$'),
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.pattern('^[^@\\s]+@[^@\\s]+\\.[^@\\s]+$'),
      ]),

      pincode: new FormControl('', [Validators.required]),
      interest: new FormControl(0),
      accept: new FormControl('', [Validators.required]),
      captcha: new FormControl('', [Validators.required]),
    });
    this.urlhttpClient = new HttpClient(handler);
  }

  ngOnInit(): void {
    this.loadCaptcha();
  }

  reloadCaptcha(): void {
    this.loadCaptcha();
  }

  loadCaptcha(): void {
    this.captchaService.getCaptcha().subscribe(
      (res: any) => {
        this.captchaData = res.body;
      },
      (err: any) => {
        this.notificationService.error('Error in Fetching the Captcha');
      }
    );
  }

  onSubmit(): void {
    if (this.contactForm.value.captcha !== this.captchaData.text) {
      this.wrongCaptcha = true;
      this.loadCaptcha();
      return;
    } else {
      this.wrongCaptcha = true;
    }
    if (this.contactForm.invalid) {
      return;
    } else {
      (<any>window).dataLayer.push({ event: 'Submit Event - 1' });
      this.close();
      this.loadService.showLoader();
      localStorage.setItem('pincode', this.contactForm.get('pincode')?.value);
      localStorage.setItem('name', this.contactForm.get('name')?.value);
      localStorage.setItem('phone', this.contactForm.get('phone')?.value);
      localStorage.setItem('email', this.contactForm.get('email')?.value);

      this.mailService
        .sendMail(
          this.country === this.constants.labels.UK
            ? {
                email: this.contactForm.get('email')?.value,
                country: this.country,
                phone: this.contactForm.get('phone')?.value,
                pincode: this.contactForm.get('pincode')?.value.toUpperCase(),
                name: this.contactForm.get('name')?.value,
                monthlyElectricityBill: '---',
                units: '---',
                panels: '---',
                roofArea: '---',
                payBackPeriod: '---',
                totalCost: '---',
                withoutSolar: '---',
                pvSize: '---',
                tariffType: '---',
                billingCycle: '---',
                savingsWithSEGPerYear: '---',
                savingsWithoutSEGPerYear: '---',
              }
            : {
                email: this.contactForm.get('email')?.value,
                country: this.country,
              }
        )
        .subscribe(
          (res: any) => {
            if (res.status === 200) {
              this.sendCURLRequest();
              this.dialog.open(ThankyoudialogComponent);
            }
            if (res.status === 406) {
              this.notificationService.warning(' Something Went Wrong');
            }
            if (res.status === 500) {
              this.notificationService.error(' Internal Error');
            }
            this.loadService.hideLoader();
          },
          (err: any) => {
            this.notificationService.error(' Error in sending mail');
            this.loadService.hideLoader();
          }
        );
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  sendCURLRequest(): void {
    // Add all the Data in URL
    let full_name = localStorage.getItem('name');
    let first_name = full_name?.split(' ')[0];
    let last_name = full_name?.split(' ')[1];
    let phone = localStorage.getItem('phone');
    let email = localStorage.getItem('email');
    let zipcode = localStorage.getItem('pincode');

    let utmSource = sessionStorage.getItem('utm_source');
    let utmMedium = sessionStorage.getItem('utm_medium');
    let utmCamp = sessionStorage.getItem('utm_campaign');
    let utmContent = sessionStorage.getItem('utm_content');
    let time = sessionStorage.getItem('duration');
    let idealRoofSize = localStorage.getItem('idealRoofSize');
    let pvSize = localStorage.getItem('pvSize');
    let newMonthlyElectricityBill = localStorage.getItem(
      'newMonthlyElectricityBill'
    );
    let panelCount = localStorage.getItem('panelCount');
    let savingsFor25Years = localStorage.getItem('savingsFor25Years');
    let estimatedPrice = localStorage.getItem('estimatedPrice');
    let timeSpent = new Date(time as any).getTime();
    const interest = Number(this.contactForm.value.interest);
    const state = localStorage.getItem('state') || '';
    const curentTime = new Date().getTime();

    const spent = Math.floor((curentTime - timeSpent) / 1000);

    let detectOS = 'Unknown OS';
    let browserName = 'Unknown';
    if (navigator.platform.indexOf('Win') !== -1) {
      detectOS = 'Windows';
    }

    if (navigator.platform.indexOf('Mac') !== -1) {
      detectOS = 'MacOS';
    }

    if (navigator.platform.indexOf('Linux') !== -1) {
      detectOS = 'Linux';
    }
    const osv = navigator.appVersion.split(' ');
    const v = [];
    v.push(osv[1]);
    v.push(osv[2]);
    v.push(osv[3]);
    v.push(osv[4]);
    v.push(osv[5]);
    v.join(' ');

    if (
      (navigator.userAgent.indexOf('Opera') ||
        navigator.userAgent.indexOf('OPR')) !== -1
    ) {
      browserName = 'Opera';
    } else if (navigator.userAgent.indexOf('Chrome') !== -1) {
      browserName = 'Chrome';
    } else if (navigator.userAgent.indexOf('Safari') !== -1) {
      browserName = 'Safari';
    } else if (navigator.userAgent.indexOf('Firefox') !== -1) {
      browserName = 'Firefox';
    } else if (navigator.userAgent.indexOf('MSIE') !== -1) {
      // IF IE > 10
      browserName = 'IE';
    } else {
      browserName = 'Unknown';
    }

    const bVersion = (() => {
      var ua = navigator.userAgent,
        tem,
        M =
          ua.match(
            /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
          ) || [];
      if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return 'IE ' + (tem[1] || '');
      }
      if (M[1] === 'Chrome') {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
        if (tem != null) {
          return tem.slice(1).join(' ').replace('OPR', 'Opera');
        }
      }
      M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
      if ((tem = ua.match(/version\/(\d+)/i)) != null) {
        M.splice(1, 1, tem[1]);
      }
      return M.join(' ');
    })();

    this.indUrl = `
      ${environment.lead_conduit_IND}?
      first_name=${first_name}&last_name=${last_name}&email=${email}&zipcode=${zipcode}&contact_number=${phone}
      &country=${this.country}&consent=TRUE&product=${
      interest === 1 ? 'solarstorage' : 'solar'
    }&home_size=&pv_capacity=&utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCamp}&utm_content=${utmContent}
      &time_spent_on_page=${
        spent + ' s'
      }&os_name=${detectOS}&os_version=${v}&browser_name=${browserName}&browser_version=${bVersion}
      &navigator_user_agent=${navigator.userAgent}&navigator_app_version=${
      navigator.appVersion
    }&navigator_platform=${navigator.platform}
      &navigator_vendor=${
        navigator.vendor
      }&ec_bill_amt=&existing_panels_count=&total_savings=
      &total_cost=&payback_period=&no_of_units=&page_url=&source_details=&oid=00D63000000U0i6&new_electricity_bill=&original_source=${'IN System Estimator'}&state=${state}&lead_source_page_url=${
      window.location.href
    }`;

    this.ukUrl = `
      ${environment.lead_conduit_UK}?
      first_name=${first_name}&last_name=${last_name}&email=${email}&zipcode=${zipcode}&contact_number=${phone}
      &country=${
        this.country
      }&consent=TRUE&product=solar&home_size=&pv_capacity=&utm_source=${utmSource}&utm_medium=${utmMedium}&utm_content=${utmContent}
      &time_spent_on_page=${
        spent + ' s'
      }&os_version=${v}&browser_name=${browserName}&browser_version=${bVersion}
      &navigator_user_agent=${navigator.userAgent}&navigator_app_version=${
      navigator.appVersion
    }&navigator_platform=${navigator.platform}
      &navigator_vendor=${
        navigator.vendor
      }&ec_bill_amt=&total_savings=&total_cost=&payback_period=&existing_panels_count=&no_of_units=&new_electricity_bill=
      &lead_source_page_url=${
        window.location.href
      }&original_source=${'UK System Estimator'}&state=&tariff_type=&billing_cycle=&savings_without_seg=&savings_without_SEG=&storage_interest=`;

    this.urlhttpClient
      .get(
        this.country === this.constants.labels.UK ? this.ukUrl : this.indUrl,
        {
          headers: new HttpHeaders().set(
            'Content-Type',
            'application/x-www-form-urlencoded'
          ),
          responseType: 'text',
        }
      )
      .subscribe(
        (res: any) => {
          if (res.status === 200) {
          }
          if (res.status === 406) {
            this.notificationService.warning('Something Went Wrong');
          }
          if (res.status === 500) {
            this.notificationService.error('Internal Error');
          }
        },
        (err) => {
          this.notificationService.error('Error in fetching data');
        }
      );
  }
}
