<button class="close-btn" mat-dialog-close>
  <img src="/assets/images/close_icon.svg" width="14" height="14" />
</button>
<div class="modal-container">
  <p class="m-title">
    {{ this.constants.labels.modal_header }}
  </p>
  <form class="c-form" [formGroup]="contactForm" (ngSubmit)="onSubmit()">
    <div class="validation-wrapper">
      <mat-form-field appearance="outline">
        <mat-label
          >{{ this.constants.labels.full_name }}<span>*</span></mat-label
        >
        <input
          type="text"
          matInput
          placeholder="{{ this.constants.labels.full_name }}"
          name="name"
          formControlName="name"
        />
      </mat-form-field>
      <div
        class="invalid-data"
        *ngIf="
          contactForm.controls.name.invalid && contactForm.controls.name.touched
        "
      >
        <div *ngIf="contactForm.controls.name.errors?.required">
          {{ constants.error.NAME_REQUIRED }}
        </div>
      </div>
    </div>
    <div class="validation-wrapper">
      <mat-form-field appearance="outline">
        <mat-label>{{ constants.labels.phone_no }}<span>*</span></mat-label>
        <input
          type="tel"
          matInput
          placeholder="{{ constants.labels.phone_no }}"
          formControlName="phone"
        />
        <span matPrefix>{{
          country === constants.labels.UK ? "0 " : "+91"
        }}</span>
      </mat-form-field>
      <div
        class="invalid-data"
        *ngIf="
          contactForm.controls.phone.invalid &&
          contactForm.controls.phone.touched
        "
      >
        <div *ngIf="contactForm.controls.phone.errors?.required">
          {{ constants.error.PHONE_NUMBER_REQUIRED }}
        </div>
        <div *ngIf="contactForm.controls.phone.errors?.pattern">
          {{ constants.error.PHONE_NUMBER_INVALID }}
        </div>
        <div
          *ngIf="
            contactForm.controls.phone.errors?.minlength ||
            contactForm.controls.phone.errors?.maxlength
          "
        >
          {{ constants.error.PHONE_NUMBER_LENGTH_INVALID }}
        </div>
      </div>
    </div>
    <div class="validation-wrapper">
      <mat-form-field appearance="outline">
        <mat-label>{{ constants.labels.Email }}<span>*</span></mat-label>
        <input
          type="text"
          matInput
          placeholder="{{ constants.labels.Email }}"
          formControlName="email"
        />
      </mat-form-field>
      <div
        class="invalid-data"
        *ngIf="
          contactForm.controls.email.invalid &&
          contactForm.controls.email.touched
        "
      >
        <div *ngIf="contactForm.controls.email.errors?.required">
          {{ constants.error.EMAIL_REQUIRED }}
        </div>
        <div *ngIf="contactForm.controls.email.errors?.pattern">
          {{ constants.error.EMAIL_INVALID }}
        </div>
      </div>
    </div>
    <div class="row mb-3" *ngIf="captchaData.text">
      <div class="col-auto position-relative">
        <button
          type="button"
          class="reload-catcha-btn"
          (click)="reloadCaptcha()"
        >
          <img src="assets/images/reload.svg" width="18" height="18" />
        </button>
        <img class="br-captcha" [src]="captchaData.image" />
      </div>
      <div class="col-12 col-sm mt-2 mt-sm-0">
        <mat-form-field appearance="outline">
          <mat-label>{{ constants.labels.Captcha }}<span>*</span></mat-label>
          <input
            matInput
            placeholder="Enter Captcha"
            formControlName="captcha"
          />
        </mat-form-field>
        <div
          class="invalid-data"
          *ngIf="
            contactForm.controls.captcha.invalid &&
            contactForm.controls.captcha.touched
          "
        >
          <div *ngIf="contactForm.controls.captcha.errors?.required">
            {{ constants.error.CAPTCHA_REQUIRED }}
          </div>
        </div>
        <div class="invalid-data" *ngIf="wrongCaptcha">
          {{ constants.error.CAPTCHA_MISMATCH }}
        </div>
      </div>
    </div>
    <div class="validation-wrapper">
       <div style="margin-bottom: 10px" *ngIf="country === constants.labels.UK">
      <mat-radio-group
        aria-label="Accept Privacy Policy and Terms and Condition" formControlName="interest"
      >
        <mat-radio-button value="1"></mat-radio-button>
        <p class="c-radio-label">
          {{constants.labels.uk_form_agree}}
        </p>
      </mat-radio-group>
    </div>
      <mat-radio-group
        aria-label="Accept Privacy Policy and Terms and Condition"
        formControlName="accept"
      >
        <mat-radio-button value="1"></mat-radio-button>
          <p class="c-radio-label">
            {{ constants.labels.policy_1 }}
            <a href="{{ constants.links.privacy_policy }}" target="_blank">{{
              constants.labels.privacy_policy
            }}</a>
            and
            <a href="{{ constants.links.terms_of_service }}" target="_blank">{{
              constants.labels.terms_of_service
            }}</a>
            <br />
            {{ constants.labels.policy_3 }}
          </p>
        
      </mat-radio-group>
      <div
        class="invalid-data"
        *ngIf="
          contactForm.controls.accept.invalid &&
          contactForm.controls.accept.touched
        "
      ></div>
    </div>
    <button
      type="submit"
      class="wt-outline-btn orange"
      [disabled]="contactForm.status === 'INVALID'"
      [ngClass]="{ disabled: contactForm.status === 'INVALID' }"
    >
      {{ constants.labels.submit }}
    </button>
    <p class="form-info">
      {{ constants.labels.info }}
    </p>
    <p class="form-info">
      {{ constants.labels.modal_note }}
    </p>
  </form>
</div>
