<div>
  <h2 class="steps-heading">{{ constants.labels.recommend_text }}</h2>

  <div class="rec-wrapper" @flyIn *ngIf="recommendedData">
    <div class="rec-block-1">
      <img src="/assets/images/solarpannel.JPG" width="133" />
      <p>
        {{ slideValue }} kW
        <strong
          >-
          {{
            country === constants.labels.UK
              ? recommendedData?.panelsNeeded || 0
              : recommendedData?.panelCount || 0
          }}{{ constants.labels.solar_panels }}</strong
        >
      </p>
      <div *ngIf="country === constants.labels.IN">
        <mat-slider
          class="c-slider"
          min="1"
          max="30"
          step=".1"
          [value]="slideValue"
          (input)="slideValue = $event.value || slideValue"
          (change)="calleditPVSizeAPI(slideValue)"
        >
        </mat-slider>
      </div>

      <div class="position-relative" *ngIf="country === constants.labels.UK">
        <mat-slider
          class="c-slider"
          min="1"
          max="15"
          step=".1"
          [value]="slideValue"
          (input)="slideValue = $event.value || slideValue"
          (change)="calleditPVSizeAPI(slideValue)"
        >
        </mat-slider>
        <div class="default-tooltip-wrapper">
          <span
            class="default-value-icon"
            matTooltipClass="c-tooltip w-max-322"
            matTooltip="This arrow represents optimal system size basis your electricity bill.&#13;&#13;Please note your savings are optimum at this system size."
            [matTooltipPosition]="'above'"
            (click)="getToDefault()"
            #triangletooltip="matTooltip"
          >
            <img
              src="/assets/images/Triangle slider.svg"
              width="18"
              height="18"
              class="triangle"
            />
          </span>
        </div>
      </div>
      <p>
        {{
          country === constants.labels.UK
            ? recommendedData?.roofSize || 0
            : recommendedData?.idealRoofSize || 0
        }}{{ constants.labels.roof_required }}
        <span
          *ngIf="country === constants.labels.UK"
          class="info-icon-1"
          matTooltipClass="c-tooltip w-max-322"
          matTooltip="{{ constants.labels.uk_roof_top_tooltip }}"
          [matTooltipPosition]="'above'"
          (click)="rooftooltip.toggle()"
          #rooftooltip="matTooltip"
        >
          <img src="/assets/images/question_mark.svg" width="14" height="14" />
        </span>
        <span
          *ngIf="country === constants.labels.IN"
          class="info-icon-1"
          matTooltipClass="c-tooltip w-max-322"
          matTooltip="{{ constants.labels.ind_roof_top_tooltip }}"
          [matTooltipPosition]="'above'"
          (click)="billtooltip.toggle()"
          #billtooltip="matTooltip"
        >
          <img src="/assets/images/question_mark.svg" width="14" height="14" />
        </span>
      </p>
    </div>

    <!-- ./rec-block-1 -->
    <div class="rec-block-2">
      <div class="highlight-block row m-0 in" *ngIf="country === constants.labels.IN">
        <div>
          <img
            src="{{ '/assets/images/' + country + '/money-hand.svg' }}"
            width="36"
            height="32"
          />
        </div>
        <div>
          <div *ngIf="country === constants.labels.IN">
            <p>{{ constants.labels.savings_over }}</p>
            <p>
              {{ recommendedData?.savingsFor25Years | indiancurrency }}
            </p>
            <div
              class="info-icon-2"
              (click)="tooltip.toggle()"
              #tooltip="matTooltip"
              matTooltipClass="c-tooltip w-max-322"
              matTooltip="{{ constants.labels.ind_savings_tooltip }}"
              [matTooltipPosition]="'above'"
            >
              <img
                src="/assets/images/question_mark.svg"
                width="17"
                height="17"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class="highlight-block row m-0"
        *ngIf="country === constants.labels.UK"
      >
        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
          <img
            src="{{ '/assets/images/' + country + '/money-hand.svg' }}"
            width="36"
            height="32"
          />
        </div>
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <p>{{ constants.labels.savings_over_uk }}</p>
          <p *ngIf="isSEG">
            {{
              recommendedData?.savingsWithSEGPerYear > 0
                ? (recommendedData?.savingsWithSEGPerYear
                    | currency: "GBP":"symbol":"1.0-0") || 0
                : "---"
            }}
          </p>
          <p *ngIf="!isSEG">
            {{
              recommendedData?.savingsWithoutSEGPerYear > 0
                ? (recommendedData?.savingsWithoutSEGPerYear
                    | currency: "GBP":"symbol":"1.0-0") || 0
                : "---"
            }}
          </p>
          <div
            *ngIf="isSEG"
            class="info-icon-2"
            (click)="tooltip.toggle()"
            #tooltip="matTooltip"
            matTooltip="These are estimated savings to you over 25 years - Enphase systems last long, and once your system pays back for itself each month, you will save on electricity bills you would otherwise have to pay. &#13;&#13; Under the Smart Export Guarantee (SEG) scheme you get paid for surplus electricity fed into the grid. Please note you need to sign up for SEG by fulfilling criteria set by the company provider, who decides rate for the electricity fed, length of contract and other terms. For calculation purpose rate of 4.6p/kW is considered here."
            [matTooltipClass]="'my-tooltip c-tooltip w-max-322'"
            [matTooltipPosition]="'above'"
          >
            <img
              src="/assets/images/question_mark.svg"
              width="17"
              height="17"
            />
          </div>
          <div
            *ngIf="!isSEG"
            class="info-icon-2"
            (click)="tooltip.toggle()"
            #tooltip="matTooltip"
            matTooltipClass="c-tooltip w-max-322"
            matTooltip="These are estimated savings to you over 25 years. Without Smart Export Guarantee & storage the surplus energy remains unused. With increase in system size, system cost goes up resulting in reduction in savings without SEG.&#13;&#13; Please note your savings are optimum when chosen system size is equal to recommended system size."
            [matTooltipPosition]="'above'"
          >
            <img
              src="/assets/images/question_mark.svg"
              width="17"
              height="17"
            />
          </div>
          <div class="c-checkbox-toggle">
            <span [ngClass]="{ active: isSEG }">{{
              constants.labels.including_seg
            }}</span>
            <label>
              <input
                type="checkbox"
                name="SEG"
                [value]="true"
                [(ngModel)]="isSEG"
              />
              <div class="c-check-toggle"></div>
            </label>
            <span [ngClass]="{ active: !isSEG }">{{
              constants.labels.excluding_seg
            }}</span>
          </div>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
          <button type="button" class="outline-btn-re" (click)="detailsModal()">
            Details
          </button>
        </div>
      </div>
      <!-- ./highlight-block -->
      <div class="d-flex bg-gray">
        <div class="info-card">
          <img
            src="{{ '/assets/images/' + country + '/bill.svg' }}"
            width="30"
          />
          <p>
            {{
              country === constants.labels.UK
                ? constants.labels.new_electricity_bill_uk
                : constants.labels.new_electricity_bill
            }}
          </p>
          <p *ngIf="country === constants.labels.UK">
            {{
              recommendedData.newElectrictyBill
                | currency: "GBP":"symbol":"1.0-0"
            }}
          </p>
          <p *ngIf="country === constants.labels.IN">
            {{ recommendedData?.newMonthlyElectricityBill | indiancurrency }}
          </p>
          <div
            *ngIf="country === constants.labels.UK"
            class="info-icon"
            matTooltipClass="c-tooltip w-max-322"
            matTooltip="{{ constants.labels.ebill_tooltip }}"
            [matTooltipPosition]="'above'"
            (click)="billtooltip.toggle()"
            #billtooltip="matTooltip"
          >
            <img
              src="/assets/images/question_mark.svg"
              width="14"
              height="14"
            />
          </div>
          <div
            *ngIf="country === constants.labels.IN"
            class="info-icon"
            matTooltipClass="c-tooltip w-max-322"
            matTooltip="{{ constants.labels.ebill_tooltip }}"
            [matTooltipPosition]="'above'"
            (click)="billtooltip.toggle()"
            #billtooltip="matTooltip"
          >
            <img
              src="/assets/images/question_mark.svg"
              width="14"
              height="14"
            />
          </div>
        </div>
        <div class="info-card">
          <img
            src="{{ '/assets/images/' + country + '/payback-period.svg' }}"
            width="24"
          />
          <p>{{ constants.labels.payback_period }}</p>
          <p>
            {{
              country === constants.labels.UK
                ? recommendedData?.payBackperiod > 25
                  ? "25"
                  : recommendedData?.payBackperiod < 0
                  ? "0"
                  : recommendedData?.payBackperiod
                : recommendedData?.payBackPeriod > 25
                ? "25"
                : recommendedData?.payBackPeriod < 0
                ? "0"
                : recommendedData?.payBackPeriod
            }}
            {{ constants.labels.year }}
          </p>

          <div
            *ngIf="country === constants.labels.UK"
            class="info-icon"
            matTooltip="{{ constants.labels.payback_period_tooltip }}"
            [matTooltipPosition]="'above'"
            matTooltipClass="c-tooltip w-max-322"
            (click)="paybacktooltip.toggle()"
            #paybacktooltip="matTooltip"
          >
            <img
              src="/assets/images/question_mark.svg"
              width="14"
              height="14"
            />
          </div>
          <div
            *ngIf="country === constants.labels.IN"
            class="info-icon"
            matTooltip="{{ constants.labels.payback_period_tooltip }}"
            [matTooltipPosition]="'above'"
            matTooltipClass="c-tooltip w-max-322"
            (click)="paybacktooltip.toggle()"
            #paybacktooltip="matTooltip"
          >
            <img
              src="/assets/images/question_mark.svg"
              width="14"
              height="14"
            />
          </div>
        </div>
        <div class="info-card">
          <img
            src="{{ '/assets/images/' + country + '/total-cost.svg' }}"
            width="22"
          />
          <p>
            {{
              country === constants.labels.UK
                ? constants.labels.total_cost_uk
                : constants.labels.total_cost
            }}
          </p>
          <p *ngIf="country === constants.labels.UK">
            {{ estPrice | currency: "GBP":"symbol":"1.0-0" }}
          </p>
          <p *ngIf="country === constants.labels.IN">
            {{ estPrice | indiancurrency }}
          </p>
          <div
            *ngIf="country === constants.labels.UK"
            class="info-icon"
            matTooltip="{{ constants.labels.total_cost_tooltip }}"
            [matTooltipPosition]="'above'"
            matTooltipClass="c-tooltip w-max-258"
            (click)="costtooltip.toggle()"
            #costtooltip="matTooltip"
          >
            <img
              src="/assets/images/question_mark.svg"
              width="14"
              height="14"
            />
          </div>
          <div
            *ngIf="country === constants.labels.IN"
            class="info-icon"
            matTooltip="{{ constants.labels.total_cost_tooltip_ind }}"
            [matTooltipPosition]="'above'"
            matTooltipClass="c-tooltip w-max-258"
            (click)="costtooltip.toggle()"
            #costtooltip="matTooltip"
          >
            <img
              src="/assets/images/question_mark.svg"
              width="14"
              height="14"
            />
          </div>
        </div>
      </div>
      <p class="est-text" *ngIf="country === constants.labels.UK">
        {{ constants.labels.block_para }}
      </p>
    </div>
    <!-- ./rec-block-2 -->
  </div>

  <div class="interested-wrapper d-block-ipad">
    <p>{{ constants.labels.interested }}</p>
    <button class="primary" type="button" (click)="openDialog()">
      <img src="/assets/images/contact-enphase.svg" width="30" height="30" />
      {{ constants.labels.contact_enphase }}
    </button>
    <button
      class="download-btn"
      type="button"
      (click)="downloadPDF()"
      [disabled]="!showDownloadButton"
    >
      <img src="/assets/images/download-pdf.svg" width="15.85" height="20" />
      {{ constants.labels.download }}
    </button>
    <div
      class="download-tooltip-hover"
      *ngIf="!showDownloadButton"
      matTooltip="{{ constants.labels.download_tooltip }}"
      [matTooltipPosition]="'above'"
      matTooltipClass="c-tooltip w-max-258"
      (click)="downloadtooltip.toggle()"
      #downloadtooltip="matTooltip"
    >
      &nbsp;
    </div>
    <!-- <span class="download-text" *ngIf="!showDownloadButton">
      Please fill the Contact Enphase form to download the report
    </span> -->
  </div>

  <div class="text-center">
    <div class="info-center-wrapper">
      <div>
        <img
          src="{{ '/assets/images/' + country + '/safety-first.svg' }}"
          width="country === constants.labels.UK ? 64: 37"
          height="country === constants.labels.UK ? 64: ''"
        />
        <p
          [ngClass]="
            country === constants.labels.UK ? 'white-txt' : 'white-txt-in'
          "
        >
          {{ constants.labels.safety_first }}
        </p>
      </div>
      <div>
        <img
          src="{{ '/assets/images/' + country + '/smart-power.svg' }}"
          width="country === constants.labels.UK ? 64: 32"
          height="country === constants.labels.UK ?64: ''"
        />
        <p
          [ngClass]="
            country === constants.labels.UK ? 'white-txt' : 'white-txt-in'
          "
        >
          {{ constants.labels.smart_power }}
        </p>
      </div>
      <div>
        <img
          src="{{ '/assets/images/' + country + '/super-savings.svg' }}"
          width="country === constants.labels.UK ? 64: 56"
          height="country === constants.labels.UK ?64:''"
        />
        <p
          [ngClass]="
            country === constants.labels.UK ? 'white-txt' : 'white-txt-in'
          "
        >
          {{ constants.labels.super_savings }}
        </p>
      </div>
      <div *ngIf="country === constants.labels.UK">
        <img src="/assets/images/Encharge.svg" width="64" height="64" />
        <p class="white-txt">{{ constants.labels.storage_ready }}</p>
      </div>
    </div>
    <br />
    <p class="note-text white-txt text-center">{{ constants.labels.note }}​</p>
  </div>

  <div class="interested-wrapper d-none-ipad">
    <p>{{ constants.labels.interested }}</p>
    <button class="primary" type="button" (click)="openDialog()">
      <img src="/assets/images/contact-enphase.svg" width="30" height="30" />
      {{ constants.labels.contact_enphase }}
    </button>
    <button
      class="download-btn"
      type="button"
      (click)="downloadPDF()"
      [disabled]="!showDownloadButton"
    >
      <img src="/assets/images/download-pdf.svg" width="15.85" height="20" />
      {{ constants.labels.download }}
    </button>
    <div
      class="download-tooltip-hover"
      *ngIf="!showDownloadButton"
      matTooltip="{{ constants.labels.download_tooltip }}"
      [matTooltipPosition]="'above'"
      matTooltipClass="c-tooltip w-max-258"
      (click)="downloadtooltip.toggle()"
      #downloadtooltip="matTooltip"
    >
      &nbsp;
    </div>
  </div>
  <!-- ./interested-wrapper -->

  <div class="edit-widget-wrapper">
    <p>
      {{ country === constants.labels.UK ? actualPin : pin }},
      {{
        country === constants.labels.UK
          ? recommendedData?.estimatedUnits || 0
          : recommendedData?.units || 0
      }}{{
        country === constants.labels.UK
          ? constants.labels.units_per_month || 0
          : constants.labels.units || 0
      }}
    </p>
    <button (click)="showEditSidePannel = !showEditSidePannel">
      {{ constants.labels.EDIT }}
    </button>
  </div>
</div>

<div class="side-pannel" [ngClass]="{ open: showEditSidePannel }">
  <button class="side-pannel-close" (click)="showEditSidePannel = false">
    <img src="/assets/images/close_icon.svg" width="20" height="20" />
  </button>

  <p class="side-pannel-heading">{{ constants.labels.update_info }}</p>
  <form class="c-form" [formGroup]="editForm">
    <div class="validation-wrapper">
      <mat-form-field appearance="outline">
        <mat-label>{{
          country === constants.labels.UK
            ? constants.labels.POSTCODE
            : constants.labels.PINCODE
        }}</mat-label>
        <input
          matInput
          placeholder="{{
            country === constants.labels.UK
              ? constants.labels.POSTCODE
              : constants.labels.PINCODE
          }}"
          formControlName="postcode"
          [readonly]="country === constants.labels.UK ? true : false"
          [ngClass]="country === constants.labels.UK ? 'disable' : ''"
        />
      </mat-form-field>
      <div
        class="invalid-data"
        *ngIf="
          editForm.controls.postcode.invalid &&
          editForm.controls.postcode.touched
        "
      >
        {{ constants.error.POSTCODE_REQUIRED }}
      </div>
      <div class="invalid-data" *ngIf="message">
        {{ message }}
      </div>
    </div>
    <div class="validation-wrapper" *ngIf="country === constants.labels.UK">
      <mat-form-field appearance="outline">
        <mat-label>{{ constants.labels.tariff_type }}</mat-label>
        <mat-select
          placeholder="{{ constants.labels.select }}"
          formControlName="tariffType"
        >
          <mat-option value="{{ constants.labels.Standard }}">{{
            constants.labels.standard_tariff
          }}</mat-option>
          <mat-option value="{{ constants.labels.Economy }}">{{
            constants.labels.economy
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <div
        class="invalid-data"
        *ngIf="
          editForm.controls.tariffType.invalid &&
          editForm.controls.tariffType.touched
        "
      >
        {{ constants.error.TARIFF_TYPE_REQUIRED }}
      </div>
    </div>
    <div class="validation-wrapper" *ngIf="country === constants.labels.UK">
      <mat-form-field appearance="outline">
        <mat-label>{{ constants.labels.billing_cycle }}</mat-label>
        <mat-select
          placeholder="{{ constants.labels.select }}"
          formControlName="billingCycle"
        >
          <mat-option value="{{ constants.labels.monthly }}">{{
            constants.labels.monthly
          }}</mat-option>
          <mat-option value="{{ constants.labels.quarterly }}">{{
            constants.labels.quarterly
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <div
        class="invalid-data"
        *ngIf="
          editForm.controls.billingCycle.invalid &&
          editForm.controls.billingCycle.touched
        "
      >
        {{ constants.error.BILLING_CYCLE_REQUIRED }}
      </div>
    </div>
    <div class="validation-wrapper">
      <mat-form-field appearance="outline">
        <mat-label>{{
          country === constants.labels.UK
            ? constants.labels.avg_monthly_bill_uk
            : constants.labels.avg_monthly_bill
        }}</mat-label>
        <input
          matInput
          placeholder="{{ constants.labels.euro }}"
          formControlName="averageMonthlyBill"
        />
      </mat-form-field>
      <div
        class="invalid-data"
        *ngIf="
          editForm.controls.averageMonthlyBill.invalid &&
          editForm.controls.averageMonthlyBill.touched
        "
      >
        {{ constants.error.BILL_REQUIRED }}
      </div>
    </div>
    <div class="en-con-wrapper">
      {{ constants.labels.estimated }}
      <span
        >{{
          country === constants.labels.UK
            ? recommendedData?.estimatedUnits || 0
            : recommendedData?.units || 0
        }}
        {{ constants.labels.units }}</span
      >
      <div class="edit-wrapper">
        <button (click)="showEdit = true">{{ constants.labels.Edit }}</button>
        <div class="edit-slider-wrapper" *ngIf="showEdit && showEditSidePannel">
          <div class="unit-box">{{ unitSlideValue }}</div>
          <p class="units-txt">{{ constants.labels.units }}</p>
          <a class="unit-done" (click)="showEdit = false">{{
            constants.labels.done
          }}</a>
          <mat-slider
            class="c-slider"
            [min]="minUnit"
            [max]="maxUnit"
            step="1"
            [value]="unitSlideValue"
            (input)="unitSlideValue = $event.value || unitSlideValue"
            (change)="getNewUnitValue(unitSlideValue)"
          >
          </mat-slider>
        </div>
      </div>
    </div>
    <button type="button" class="sub-side-btn" (click)="nextStep(editForm)">
      {{ constants.labels.submit }}
    </button>
  </form>
</div>

<div
  class="side-pannel-shadow"
  *ngIf="showEditSidePannel"
  (click)="showEditSidePannel = false"
></div>
