import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-not-available',
  templateUrl: './not-available.component.html',
  styleUrls: ['../get-started/get-started.component.scss'],
  animations: [
    trigger('flyIn', [
      state('in', style({ transform: 'translateY(0)', opacity: 1 })),
      transition('void => *', [
        style({ transform: 'translateY(-14px)', opacity: 0 }),
        animate('0.5s ease-out'),
      ]),
    ]),
  ],
})
export class NotAvailableComponent implements OnInit {
  constructor() {
    window.location.href = 'https://enphase.com';
  }

  ngOnInit(): void {}
}
