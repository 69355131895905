import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DirectContactWithExpertsComponent } from '../direct-contact-with-experts/direct-contact-with-experts.component';
import { Constant } from '../shared/constant';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  constants: Constant;
  constructor(public dialog: MatDialog, private constant: Constant) {
    this.constants = this.constant;
  }

  ngOnInit(): void {}

  openDialog() {
    this.dialog.open(DirectContactWithExpertsComponent);
  }
}
