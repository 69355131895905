import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';

import { ElecricityBillService } from '../shared/elecricity-bill.service';
import { LoaderService } from '../shared/loader.service';
import { NotificationsService } from '../shared/notifications.service';
import { Constant } from '../shared/constant';

@Component({
  selector: 'app-pin-code',
  templateUrl: './pin-code.component.html',
  styleUrls: ['./pin-code.component.scss'],
  animations: [
    trigger('flyIn', [
      state('in', style({ transform: 'translateY(0)', opacity: 1 })),
      transition('void => *', [
        style({ transform: 'translateY(-14px)', opacity: 0 }),
        animate('0.5s ease-out'),
      ]),
    ]),
  ],
})
export class PinCodeComponent implements OnInit {
  pincodeForm: FormGroup;
  message = '';
  text = '';
  country: any;
  constants: Constant;

  constructor(
    private notificationService: NotificationsService,
    private router: Router,
    private formBuilder: FormBuilder,
    private eService: ElecricityBillService,
    private loadService: LoaderService,
    private route: ActivatedRoute,
    private constant: Constant
  ) {
    this.constants = this.constant;
    this.pincodeForm = this.formBuilder.group({
      pincode: ['', Validators.required],
    });
    this.country = localStorage.getItem('country');
  }

  ngOnInit(): void {
    document
      .querySelector(
        this.country === this.constants.labels.UK
          ? '.main-bg-uk'
          : '.main-bg-in'
      )
      ?.classList.add('dark-bg');

    this.text =
      this.country === this.constants.labels.UK
        ? this.constants.labels.POSTCODE
        : this.constants.labels.PINCODE;
  }

  nextStep(pin: any): void {
    if (this.country === this.constants.labels.UK) {
      const trimedPin = pin.trim().toUpperCase();

      if (trimedPin === '') {
        this.message = this.constants.error.POSTCODE_BLANK_ERROR;
      } else {
        if (trimedPin.match(/\s/g)) {
          this.message = this.constants.error.SPACE_ERROR;
        } else {
          localStorage.setItem('actualPincode', trimedPin);

          const modPin = [];
          for (let i = 0; i < trimedPin.length - 3; i++) {
            modPin.push(trimedPin[i]);
          }
          const pincode = modPin.join('');
          this.loadService.showLoader();
          this.slabData(pincode);
        }
      }
    } else {
      if (String(pin) === '' || String(pin) === null) {
        this.message = this.constants.error.PINCODE_BLANK_ERROR;
      } else if (String(pin).length > 6) {
        this.message = this.constants.error.PINCODE_EXCEED_ERROR;
      } else if (String(pin).length > 0 && String(pin).length < 6) {
        this.message = this.constants.error.PINCODE_VALID_ERROR;
      } else {
        this.slabData(pin);
      }
    }
  }

  slabData(pin: any): void {
    this.loadService.showLoader();
    this.eService.getRegionData(this.country, pin).subscribe(
      (res: any) => {
        if (res.status === 200) {
          if (typeof res.body.result === 'object') {
            localStorage.setItem('pincode', pin);
            localStorage.setItem('state', res.body.result.stateName);
            this.message = '';
            this.router.navigate(['../bill'], { relativeTo: this.route });
          } else {
            this.message = res.body.result;
          }
        }
        if (res.status === 406) {
          this.notificationService.warning(
            this.constants.error.SOMETHING_WENT_WRONG
          );
        }
        if (res.status === 500) {
          this.notificationService.error(this.constants.error.INTERNAL_ERROR);
        }
        this.loadService.hideLoader();
      },
      (err: any) => {
        // this.notificationService.error(this.constants.error.FETCH_DATA_ERROR);
        this.loadService.hideLoader();
      }
    );
  }
}
