import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { GetStartedComponent } from './get-started/get-started.component';
import { PinCodeComponent } from './pin-code/pin-code.component';
import { AverageBillComponent } from './average-bill/average-bill.component';
import { RecommendedComponent } from './recommended/recommended.component';
import { NotAvailableComponent } from './not-available/not-available.component';

const routes: Routes = [
  {
    path: 'unavailable',
    component: NotAvailableComponent,
  },
  {
    path: ':country',
    children: [
      {
        path: '',
        component: GetStartedComponent,
      },
      {
        path: 'pin',
        component: PinCodeComponent,
      },
      {
        path: 'bill',
        component: AverageBillComponent,
      },
      {
        path: 'bill/:edit',
        component: AverageBillComponent,
      },
      {
        path: 'your-estimation',
        component: RecommendedComponent,
      },
      {
        path: 'your-estimation/:editedUnits',
        component: RecommendedComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
