import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import baseapi from './base/baseapi.json';
@Injectable({
  providedIn: 'root',
})
export class pdfService {
  showDownloadButton = new BehaviorSubject(false);
  sendUserData = new BehaviorSubject({});

  constructor(private http: HttpClient) {}
  getHeader(): any {
    return new HttpHeaders().set('Accept', 'application/pdf');
  }

  getPdfForUK(
    country: any,
    name: any,
    phone: any,
    email: any,
    monthlyElectricityBill: any,
    units: any,
    panels: any,
    roofArea: any,
    payBackPeriod: any,
    withoutSolar: any,

    pincode: any,
    totalCost: any,
    tariffType: string,
    billingCycle: string,
    savingsWithSEGPerYear: any,
    savingsWithoutSEGPerYear: any,
    pvSize: any
  ): any {
    return this.http
      .get<any>(baseapi.getPdf, {
        params: {
          country,
          name,
          phone,
          email,
          monthlyElectricityBill,
          units,
          panels,
          roofArea,
          payBackPeriod,
          withoutSolar,
          pincode,
          totalCost,
          tariffType,
          billingCycle,
          savingsWithSEGPerYear,
          savingsWithoutSEGPerYear,
          pvSize,
        },
        headers: this.getHeader(),
        responseType: 'blob' as 'json',
      })
      .pipe(
        map((getPdf) => {
          return getPdf;
        })
      );
  }
  getPdfForIN(
    name: any,
    phone: any,
    email: any,
    monthlyElectricityBill: any,
    units: any,
    panels: any,
    roofArea: any,
    payBackPeriod: any,
    withoutSolar: any,
    savings: any,
    pincode: any,
    totalCost: any,
    pvSize: any,
    country: any
  ): any {
    return this.http
      .get<any>(baseapi.getPdf, {
        params: {
          name,
          phone,
          email,
          monthlyElectricityBill,
          units,
          panels,
          roofArea,
          payBackPeriod,
          withoutSolar,
          savings,
          pincode,
          totalCost,
          pvSize,
          country,
        },
        headers: this.getHeader(),
        responseType: 'blob' as 'json',
      })
      .pipe(
        map((getPdf) => {
          return getPdf;
        })
      );
  }
}
