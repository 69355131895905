<div class="details-modal">
  <p class="mb-3 details">Details</p>
  <button class="close-btn" mat-dialog-close>
    <img src="/assets/images/close_icon.svg" width="18" height="18" />
  </button>
  <div class="container p-0">
    <div class="row text-center">
      <div class="col-lg-3 col-md-6 col-sm-12 mb-3">
        <div class="card">
          <div
            *ngIf="isSEG"
            class="info-icon-2"
            (click)="tooltip.toggle()"
            #tooltip="matTooltip"
            matTooltip="These are estimated savings to you over 25 years - Enphase systems last long, and once your system pays back for itself each month, you will save on electricity bills you would otherwise have to pay.&#13;&#13; Under the Smart Export Guarantee (SEG) scheme you get paid for surplus electricity fed into the grid. Please note you need to sign up for SEG by fulfilling criteria set by the company provider, who decides rate for the electricity fed, length of contract and other terms. For calculation purpose rate of 4.6p/kW is considered here."
            [matTooltipClass]="'my-tooltip n-tooltip w-max-322'"
            [matTooltipPosition]="'right'"
          >
            <img
              src="/assets/images/question_mark.svg"
              width="16"
              height="16"
              class="info"
            />
          </div>
          <div
            *ngIf="!isSEG"
            class="info-icon-2"
            (click)="tooltip.toggle()"
            #tooltip="matTooltip"
            matTooltipClass="n-tooltip w-max-322"
            matTooltip="These are estimated savings to you over 25 years. Without Smart Export Guarantee & storage the surplus energy remains unused. With increase in system size, system cost goes up resulting in reduction in savings without SEG.&#13;&#13; Please note your savings are optimum when chosen system size is equal to recommended system size. "
            [matTooltipPosition]="'right'"
          >
            <img
              src="/assets/images/question_mark.svg"
              width="16"
              height="16"
              class="info"
            />
          </div>
          <img
            src="{{ '/assets/images/' + country + '/money-hand.svg' }}"
            width="24"
            height="20"
            class="center"
          />
          <p class="head">Savings per year</p>
          <p *ngIf="isSEG" class="val">
            {{
              bills.savingsWithSEGPerYear > 0
                ? (bills.savingsWithSEGPerYear
                  | currency: "GBP":"symbol":"1.0-0")
                : "---"
            }}
          </p>
          <p *ngIf="!isSEG" class="val">
            {{
              bills.savingsWithoutSEGPerYear > 0
                ? (bills.savingsWithoutSEGPerYear
                  | currency: "GBP":"symbol":"1.0-0")
                : "---"
            }}
          </p>
          <div class="c-checkbox-toggle">
            <span [ngClass]="{ active: isSEG }">With SEG</span>
            <label>
              <input
                type="checkbox"
                name="SEG"
                [value]="true"
                [(ngModel)]="isSEG"
              />
              <div class="c-check-toggle"></div>
            </label>
            <span [ngClass]="{ active: !isSEG }">Without SEG</span>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12 mb-3">
        <div class="card">
          <div
            class="info-icon"
            matTooltipClass="n-tooltip w-max-322"
            matTooltip="This amount is only illustrative basis the data provided by you without considering the effect of SEG rate. Your actual bill amount will depend on multiple factors such as sanctioned load, environmental conditions, your electricity consumption pattern & SEG tariff."
            [matTooltipPosition]="'right'"
            (click)="billtooltip.toggle()"
            #billtooltip="matTooltip"
          >
            <img
              src="/assets/images/question_mark.svg"
              width="16"
              height="16"
              class="info"
            />
          </div>
          <img
            src="{{ '/assets/images/' + country + '/bill.svg' }}"
            width="20"
            height="20"
            class="center"
          />
          <p class="head">New Electricity Bill</p>
          <p class="val">
            {{ bills.newElectrictyBill | currency: "GBP":"symbol":"1.0-0" }}
          </p>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12 mb-3">
        <div class="card">
          <div
            class="info-icon"
            matTooltip="The amount of time your system will pay back for itself i.e. the time it will take for you to recover your investment."
            [matTooltipPosition]="'above'"
            matTooltipClass="n-tooltip w-max-322"
            (click)="paybacktooltip.toggle()"
            #paybacktooltip="matTooltip"
          >
            <img
              src="/assets/images/question_mark.svg"
              width="16"
              height="16"
              class="info"
            />
          </div>
          <img
            src="{{ '/assets/images/' + country + '/payback-period.svg' }}"
            width="18"
            height="20"
            class="center"
          />
          <p class="head">Payback Period</p>
          <p class="val">
            {{
              bills.payBackperiod > 25
                ? "25"
                : bills.payBackperiod < 0
                ? "0"
                : bills.payBackperiod
            }}
            years
          </p>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12 mb-3">
        <div class="card">
          <div
            class="info-icon"
            matTooltip="This is an estimated cost for your full system including installation, commissioning and other charges so that you can start using your system right away."
            [matTooltipPosition]="'above'"
            matTooltipClass="n-tooltip w-max-258"
            (click)="costtooltip.toggle()"
            #costtooltip="matTooltip"
          >
            <img
              src="/assets/images/question_mark.svg"
              width="16"
              height="16"
              class="info"
            />
          </div>
          <img
            src="{{ '/assets/images/' + country + '/total-cost.svg' }}"
            width="18"
            height="20"
            class="center"
          />
          <p class="head">Total Cost</p>
          <p class="val">{{ estPrice | currency: "GBP":"symbol":"1.0-0" }}</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <div class="card-2 text-center mob">
          <p class="head">
            Electricity expenditure comparison with & without solar
          </p>
          <div class="graph mb-5">
            <div class="row">
              <div class="col-4 p-0 mr-35 first">
                <span class="ebill">{{
                  electricityBill | currency: "GBP":"symbol":"1.0-0"
                }}</span>
                <div class="bill">
                  <span class="middle">Electricity Bill</span>
                </div>
                <div class="text-center">
                  <p class="pos">
                    <span class="fh">Without</span> <br />
                    <span class="fb">Solar</span>
                  </p>
                </div>
              </div>
              <div class="col-4 p-0 second">
                <!-- <div
                  class="info-icon"
                  [matTooltip]="aboveTemplate"
                  [matTooltipPosition]="'above'"
                  matTooltipClass="b-tooltip w-max-258"
                  (click)="netTooltip.toggle()"
                  #netTooltip="matTooltip"
                > -->
                <div class="net-box">
                  <div class="middle">
                    <span>Net Saving : </span>
                    <b class="">{{ gap }}</b>
                  </div>
                </div>
                <div class="cost">
                  <span class="middle"
                    >Total cost*<span class="ct">{{
                      totalCost | currency: "GBP":"symbol":"1.0-0"
                    }}</span></span
                  >
                </div>

                <div class="bill-2">
                  <span class="middle"
                    >Electricity Bill<span class="tc">{{
                      bills.newElectrictyBill | currency: "GBP":"symbol":"1.0-0"
                    }}</span></span
                  >
                </div>

                <div class="text-center">
                  <p class="pos">
                    <span class="fh">With</span> <br />
                    <span class="fb">Solar</span>
                  </p>
                </div>
                <!-- </div> -->
              </div>
            </div>
          </div>
          <div class="note">
            *The total cost is spread over 25 years as Enphase microinverters
            come with 25 year warranty.
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
