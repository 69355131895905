<header>
  <div class="logo">
    <a
      *ngIf="country === constants.labels.IN"
      href="{{ enphaseUrl.enphase_link_IND }}"
      ><img src="/assets/images/enphase-logo.svg" width="135" height="24"
    /></a>
    <img
      src="/assets/images/enphase-logo.svg"
      width="135"
      height="24"
      *ngIf="country === constants.labels.UK"
    />
  </div>
  <p class="logo-text">{{ constants.labels.system_estimator }}</p>

  <p class="support">
    <img src="/assets/images/envelope.svg" />
    <a href="mailto:contact@enphase.com">{{
      constants.labels.enphase_support
    }}</a>
    <span class="h-tooltip"> {{ constants.labels.email }} </span>
  </p>
</header>
