import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Constant } from '../shared/constant';

@Component({
  selector: 'app-thankyoudialog',
  templateUrl: './thankyoudialog.component.html',
  styleUrls: ['./thankyoudialog.component.scss'],
})
export class ThankyoudialogComponent implements OnInit {
  constants: Constant;
  country: string | null;
  constructor(
    public dialogRef: MatDialogRef<ThankyoudialogComponent>,
    private constant: Constant
  ) {
    this.constants = this.constant;
    this.country = localStorage.getItem('country');
  }

  ngOnInit(): void {}
  close(): void {
    this.dialogRef.close();
  }
}
