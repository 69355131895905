import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LOCALE_ID, NgModule } from '@angular/core';
import { MatSliderModule } from '@angular/material/slider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RecaptchaModule } from 'angular-google-recaptcha';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { GetStartedComponent } from './get-started/get-started.component';
import { PinCodeComponent } from './pin-code/pin-code.component';
import { AverageBillComponent } from './average-bill/average-bill.component';
import { RecommendedComponent } from './recommended/recommended.component';
import { ConnectWithExpertsComponent } from './connect-with-experts/connect-with-experts.component';
import { ThankyoudialogComponent } from './thankyoudialog/thankyoudialog.component';
import { DirectContactWithExpertsComponent } from './direct-contact-with-experts/direct-contact-with-experts.component';
import { LessFivehundredComponent } from './less-fivehundred/less-fivehundred.component';
import { LargePVSizeModalComponent } from './large-pvsize-modal/large-pvsize-modal.component';
import { HttpInterceptorService } from './shared/http-interceptor.service';
import { CurrencyPipe } from '@angular/common';
import { IndiancurrencyPipe } from './shared/indiancurrency.pipe';
import { Constant } from './shared/constant';
import { NotAvailableComponent } from './not-available/not-available.component';
import { DetailsModalComponent } from './details-modal/details-modal.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    GetStartedComponent,
    PinCodeComponent,
    AverageBillComponent,
    RecommendedComponent,
    ThankyoudialogComponent,
    ConnectWithExpertsComponent,
    DirectContactWithExpertsComponent,
    LessFivehundredComponent,
    LargePVSizeModalComponent,
    IndiancurrencyPipe,
    NotAvailableComponent,
    DetailsModalComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatSliderModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    RecaptchaModule.forRoot({
      siteKey: '6LefHh0bAAAAANT_s_KyMndATuf-qNhvn0X2k52W',
    }),
    FormsModule,
    AppRoutingModule,
    MatTooltipModule,
    MatDialogModule,
    MatIconModule,
    MatSnackBarModule,
    MatInputModule,
    MatRadioModule,
    AppRoutingModule,
    HttpClientModule,
  ],
  providers: [
    Constant,
    CurrencyPipe,
    IndiancurrencyPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
