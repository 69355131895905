import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import baseapi from '../../base/baseapi.json';
@Injectable({
  providedIn: 'root',
})
export class ElectricityBillServiceUK {
  constructor(private http: HttpClient) {}
  getHeader(): any {
    return new HttpHeaders()
      .set('enctype', 'multipart/form-data')
      .set('Accept', 'application/json');
  }
  getEBilDatasForUK(
    country: any,
    pincode: any,
    averageMonthlyElectricityBill: any,
    tariffType: any,
    billingCycle: any
  ): any {
    return this.http.get(baseapi.getDatas, {
      params: {
        country,
        pincode,
        averageMonthlyElectricityBill,
        tariffType,
        billingCycle,
      },
      headers: this.getHeader(),
      observe: 'response',
    });
  }
  getEBilUnitsDatasForUK(
    country: any,
    pincode: any,
    monthlyBill: any,
    tariffType: any,
    billingCycle: any,
    editUnits: any
  ): any {
    return this.http.get(baseapi.getEditedUnitsData, {
      params: {
        country,
        pincode,
        monthlyBill,
        tariffType,
        billingCycle,
        editUnits,
      },
      headers: this.getHeader(),
      observe: 'response',
    });
  }

  editPVSizeForUK(
    country: any,
    regionCode: any,
    editPvSize: any,
    tariffType: any,
    billingCycle: any,
    monthlyBill: any
  ): any {
    return this.http.get(baseapi.getDataForEditPVSize, {
      params: {
        country,
        regionCode,
        editPvSize,
        tariffType,
        billingCycle,
        monthlyBill,
      },
      headers: this.getHeader(),
      observe: 'response',
    });
  }
}
