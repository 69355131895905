<div>
  <h2 class="steps-heading">{{ constants.labels.heading }}</h2>

  <form
    [formGroup]="pincodeForm"
    class="step-form-wrapper text-center mt-50"
    @flyIn
  >
    <p class="info-label mb-20">Enter your {{ text }}</p>
    <img src="/assets/images/map.svg" class="map-icon mb-20" alt="Map Icon" />
    <div class="position-relative">
      <input
        *ngIf="country === constants.labels.UK"
        type="text"
        class="round-input mb-55"
        placeholder="{{ text }}"
        formControlName="pincode"
      />
      <input
        *ngIf="country === constants.labels.IN"
        type="number"
        class="round-input mb-55"
        placeholder="{{ text }}"
        formControlName="pincode"
      />
      <span *ngIf="message" class="error">{{ message }}</span>
    </div>
    <div class="d-flex">
      <button
        type="button"
        class="outline-btn ml-auto"
        (click)="nextStep(pincodeForm.value.pincode)"
      >
        {{ constants.labels.next }}
      </button>
    </div>
  </form>
</div>
