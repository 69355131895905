<footer>
  <p class="f-text">
    {{ constants.labels.footer_text }}
    <button class="link-btn" type="button" (click)="openDialog()">
      {{ constants.labels.here }}
    </button>
  </p>
  <div class="text-right">
    <a href="{{ constants.links.terms_of_service }}" target="_blank"
      >Terms |
    </a>
    <a href="{{ constants.links.privacy_policy }}" target="_blank">Privacy</a>
  </div>
</footer>
