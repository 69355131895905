import { CurrencyPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TooltipComponent } from '@angular/material/tooltip';
import { Constant } from '../shared/constant';

@Component({
  selector: 'app-details-modal',
  templateUrl: './details-modal.component.html',
  styleUrls: ['./details-modal.component.scss'],
})
export class DetailsModalComponent implements OnInit {
  isSEG = true;
  bills: any;
  estPrice: any;
  totalCost: any;

  cycle: any;
  electricityBill: any;
  aboveTemplate = ``;
  constants: Constant;
  country: any;
  gap: any;
  constructor(private currencyPipe: CurrencyPipe, private constant: Constant) {
    this.constants = this.constant;
  }

  ngOnInit(): void {
    this.bills = JSON.parse(Object(localStorage.getItem('RecommendedData')));
    this.country = localStorage.getItem('country');
    this.estPrice = this.bills.estSystemPrice.toFixed(2);
    this.cycle = localStorage.getItem('cycle');
    this.electricityBill = Number(localStorage.getItem('electricityBill'));

    this.totalCost =
      this.cycle === 'Monthly'
        ? Math.floor(this.estPrice / 300)
        : Math.floor(this.estPrice / 300) * 3;

    this.setHeightfunc(this.bills, this.totalCost);
  }

  private setHeightfunc(bills: any, totalCost: any): void {
    if (totalCost > 0) {
      const perPercent = this.electricityBill / 100;
      const billHeight = bills.newElectrictyBill / perPercent;
      console.log(billHeight, perPercent);
      const esPrice = totalCost / perPercent;
      console.log(esPrice, billHeight);
      (document as any).getElementsByClassName('bill-2')[0].style.height =
        billHeight + 'px';
      (document as any).getElementsByClassName('cost')[0].style.height =
        esPrice + 'px';
      // Object.defineProperty(TooltipComponent.prototype, 'message', {
      //   set(v: any): any {
      //     const el = document.querySelectorAll('.mat-tooltip');

      //     if (el) {
      //       el[el.length - 1].innerHTML = v;
      //     }
      //   },
      //   configurable: true,
      // });

      // this.aboveTemplate = `<div>Net saving : <b>${this.currencyPipe.transform(
      //   this.electricityBill - (totalCost + bills.newElectrictyBill),
      //   'GBP',
      //   'symbol',
      //   '1.0-0'
      // )}</b></div>`;

      this.gap = this.currencyPipe.transform(
        this.electricityBill - (totalCost + bills.newElectrictyBill),
        'GBP',
        'symbol',
        '1.0-0'
      );
    } else {
      (document as any).getElementsByClassName('bill-2')[0].style.height =
        0 + 'px';
      (document as any).getElementsByClassName('cost')[0].style.height =
        0 + 'px';
      this.gap = this.currencyPipe.transform(0, 'GBP', 'symbol', '1.0-0');
    }
  }
}
